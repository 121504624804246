import { useFormikContext } from "formik";
import { Row, Col, Form, Spinner } from "react-bootstrap";
// import SelectComboBox from 'react-select';
import Select from "components/Select";

import { useGetProducers } from "hooks/useProducers";
import { leftColSize, rightColSize } from "features/IllustrationForm/constants";

function Producer() {
  const { values, touched, errors, handleChange } = useFormikContext();
  const { data = [], isLoading } = useGetProducers();
  const inputName = "producer";
  const showError = touched[inputName] && !!errors[inputName];

  if (isLoading) {
    return (
      <>
        <Spinner animation="border" size="sm" />
        <span className="d-inline-block ml-2">Loading producers</span>
      </>
    );
  }

  return (
    <Form.Group as={Row} className="align-items-center">
      <Form.Label htmlFor="producerSelect" column className={leftColSize}>
        Producer
      </Form.Label>
      <Col className={rightColSize}>
        <Select
          initial="select a producer"
          options={data}
          name={inputName}
          onChange={handleChange}
          value={values[inputName]}
          isInvalid={showError}
        />
        {showError && (
          <Form.Control.Feedback type="invalid" className="d-show">
            {errors[inputName]}
          </Form.Control.Feedback>
        )}
      </Col>
    </Form.Group>
  );
}

export default Producer;
