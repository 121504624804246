import { Form, Button } from 'react-bootstrap';
import cn from 'classnames';

const emptyFirm = {
  name: '',
  id: null,
};

function FirmForm({ firm = emptyFirm, onSubmit, onCancel, actionLabel = 'Save', ...props }) {
  return (
    <Form {...props} onSubmit={onSubmit}>
      <Form.Group controlId="newFirm">
        <Form.Label className="required">Firm Name</Form.Label>
        <Form.Control name="name" required defaultValue={firm.name} />
      </Form.Group>
      <div
        className={cn(
          'mt-3 d-flex',
          { 'justify-content-between': !!onCancel },
          { 'justify-content-end': !onCancel }
        )}
      >
        {onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit">{actionLabel}</Button>
      </div>
    </Form>
  );
}

export default FirmForm;
