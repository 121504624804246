import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, put, del } from 'utils/fetch';
import { CLIENTS, SCENARIOS, ADVISORS } from 'hooks/constants';

const cacheKey = CLIENTS.CACHE_KEY;
const endpoint = CLIENTS.ENDPOINT;

/**
 * Get all clients for a specific Advisor
 * @param {string} advisorId uuid
 * @returns {Promise<Client[]>}
 */
export function useAdvisorClients(advisorId) {
  return useQuery([cacheKey, advisorId], () => get(`${ADVISORS.ENDPOINT}/${advisorId}/clients`), {
    enabled: !!advisorId,
  });
}

/**
 * Return a paginated list of Clients for a specific advisor
 * @param {string} advisorId
 * @param {number} page
 * @returns {Promise<PaginatedClient>}
 */
 export function usePaginatedClients(advisorId, page = 1) {
  return useQuery(
    [cacheKey, advisorId, { page }],
    () => get(`${endpoint}/paginated?advisorId=${advisorId}&page=${page}`),
    {
      keepPreviousData: true,
      enabled: !!advisorId,
    }
  );
}

/**
 * Filter clients using search term
 * @returns {Promise<Client[]>}
 */
 export function useClientSearch(advisorId, searchString) {
  return useQuery([cacheKey, advisorId, { searchString }], () => get(`${endpoint}?advisorId=${advisorId}&search=${searchString}`), {
    enabled: !!searchString && !!advisorId,
  });
}

/**
 * Get a single Client
 * @param {string} id uuid
 * @returns {Promise<Client>}
 */
 export function useGetClient(id) {
  return useQuery(cacheKey, () => get(`${endpoint}/${id}`), {
    enabled: !!id,
  });
}

export function useAddAdvisorClient() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      return post(endpoint, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );
}

export function useUpdateClient() {
  const queryClient = useQueryClient();

  return useMutation((data) => put(endpoint, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKey);
    },
  });
}

export function useDeleteClient() {
  const queryClient = useQueryClient();

  return useMutation((id) => del(`${endpoint}/${id}`), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(cacheKey);
        // when a client is deleted, so are all of its scenarios.
        queryClient.invalidateQueries(SCENARIOS.CACHE_KEY);
      }, 1000);
    },
  });
}