import MainNav from 'components/MainNav';

function Header() {
  return (
    <header className="container">
      <MainNav />
    </header>
  );
}

export default Header;
