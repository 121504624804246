/**
 * Max Distribution Calculator
 * Based on a formula provided by Sam Jensen
 */

/*
			                              -0.90%
	              Factor 1	Factor 2	Factor 1 if 2 lives
$2-3 million	  1.80%	    1.10%	    0.90%
$3-4 million	  1.60%	    0.80%	    0.70%
$4-7 million	  1.10%	    0.60%	    0.20%
$7-10 million	  1.00%	    0.55%	    0.10%
$10 million+	  0.95%	    0.50%	    0.05%
*/
export function getFactor(table, whichFactor, amount, lives) {
  if (whichFactor > 2) {
    throw new Error('getFactor whichFactor can only be 1 or 2')
  }
  let row;

  if (amount < 2_000_000) {
    row = table.find(factor => factor.rangeMin === 2_000_000);
  } else if (amount >= 10_000_000) {
    row = table.find(factor => factor.rangeMin === 10_000_000);
  } else {
    for (const factor of table) {
      if (factor.rangeMax !== null && amount >= factor.rangeMin && amount < factor.rangeMax) {
        row = factor;
        break;
      }
    }
  }

  if (whichFactor === 1 && lives === 1) {
    return row.factor1_Lives1;
  }
  if (whichFactor === 1 && lives > 1) {
    return row.factor1_Lives2;
  }
  if (whichFactor === 2) {
    return row.factor2;
  }
}

/**
 * get the youngest age from array of insured
 * @typedef {object} Insured
 * @property {number} age
 *
 * @param {Insured[]} insuredArr
 */
export function getAge(insuredArr) {
  if (insuredArr.length === 1) return insuredArr[0].age;

  // get youngest
  return insuredArr.reduce((age, person) => {
    if (person.age < age) return person.age;
    return age;
  }, 999); // starting off with an unreachable age
}

const niceKeys = {
  totalAmount: 'Amount',
  distributions_startAge: 'Distribution - Start Age',
  distributions_endAge: 'Distribution - End Age',
  rateOfReturn: 'Rate of return',
  numOfPayments: 'pays',
};
export function assureValues(data) {
  const keys = [
    'totalAmount',
    'distributions_startAge',
    'distributions_endAge',
    'rateOfReturn',
    'numOfPayments',
  ];

  const errors = keys
    .map((key) => {
      if (typeof data[key] !== 'number' || data[key] === 0) {
        return `Missing value for <b>${niceKeys[key]}</b>`;
      }
      if (data[key] === 0) {
        return `<b>${niceKeys[key]}</b> should be greather than 0`;
      }
      return null;
    })
    .filter((x) => x !== null);

  if (
    !Array.isArray(data.insured) ||
    !data.insured.length ||
    !data.insured.every((obj) => typeof obj?.age === 'number')
  ) {
    errors.push(`All <b>insured</b> must have an <b>age</b>`);
  }
  return errors;
}

export function calcMax(data, factors) {
  const {
    totalAmount,
    distributions_startAge,
    distributions_endAge,
    insured,
    rateOfReturn,
    numOfPayments,
  } = data;

  /*
    $K Amount, A5 - totalAmount
    #Y Build,  B5 - dist start age - current age + num pays
    #Y Payout, C5 - dist end age - dist start age
    % Return, D5 - rate of return
    # Lives, E5 - either 1 or 2, (3 will be treated like 2)

    =(A5*(1+D5-$B$16)^B5)/C5*(1+D5-$C$16)^(C5/2)
    = ( amount * (1 + rateOfReturn - getFactor1basedOnLives) ^ build ) / payout * ( 1 + rateOfReturn - getFactor2) ^ ( payout / 2)
  */
  const age = getAge(insured);
  const lives = insured.length || 1;
  const build = distributions_startAge - numOfPayments - age;
  const payout = distributions_endAge - distributions_startAge;

  const factor1 = getFactor(factors, 1, totalAmount, lives);
  const factor2 = getFactor(factors, 2, totalAmount);

  /******************************************************
   * calculation begins
   */
  const rate1 = (100 + rateOfReturn - factor1) / 100;
  const rate2 = (100 + rateOfReturn - factor2) / 100;
  const increasedAmount = totalAmount * Math.pow(rate1, build);
  const halfPayout = payout / 2;
  const max = (increasedAmount / payout) * Math.pow(rate2, halfPayout);

  return floorTenK(max);
}

/**
 * Round down to nearest tenth thousandths place
 * @param {number} num
 * @returns a whole number rounded down to the ten thousandth place
 */
export function floorTenK(num) {
  if (num < 10_000) return num;
  const result = Math.floor(num / 10_000) * 10_000;
  return result;
}
