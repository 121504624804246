/**
 * Capitalize the first letter of a string
 * @param {string} str 
 * @returns 
 */
export function capFirst(str) {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}

/**
 * will convert a camel cased string to a nice label. Examples: 
 * - firstName --> First Name
 * - lastName --> Last Name
 * - MyCamelCasedID --> My Camel Cased ID
 * @param {string} str 
 * @returns {string}
 */
export function camelCaseToLabel(str) {
  const label = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return capFirst(label);
}