import { useQuery } from 'react-query';
import { get } from 'utils/fetch';
import { PRODUCERS } from 'hooks/constants';

const cacheKey = PRODUCERS.CACHE_KEY;
const endpoint = PRODUCERS.ENDPOINT;

export function useGetProducers() {
  return useQuery(cacheKey, () => get(endpoint), {
		// TODO: this will eventually change but for now the response is hard coded from the backend
    // so we can cache this forever and ever until that changes
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
