import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Context providers
import GlobalAlertProvider from 'providers/GlobalAlertProvider';

// pages
import LegacyForm from 'pages/LegacyForm';
import NewForm from 'pages/NewForm';
import Factors from 'pages/Factors';
import NoMatch from 'pages/NoMatch';

// routing
import PrivateRoute from 'routes/PrivateRoute';
import ApiAuthorizationRoutes from 'routes/ApiAuthorizationRoutes';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      // default Cache time is 5min so setting default staleTime to 4
      staleTime: 4 * 60 * 1000
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalAlertProvider>
        <Router basename={baseUrl}>
          <Switch>
            <PrivateRoute exact path="/" component={NewForm} />
            <PrivateRoute path="/legacy-form" component={LegacyForm} />
            <PrivateRoute path="/factors" component={Factors} />
            <Route
              path={ApplicationPaths.ApiAuthorizationPrefix}
              component={ApiAuthorizationRoutes}
            />
            <Route path="*">
            <NoMatch />
          </Route>
          </Switch>
        </Router>
      </GlobalAlertProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
