import { useFormikContext } from "formik";
import cn from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Insured from "features/IllustrationForm/Insured";
import Amount from "features/IllustrationForm/Amount";
import Exchange from "features/IllustrationForm/Exchange";
import Owner from "features/IllustrationForm/Owner";
import Producer from "features/IllustrationForm/Producer";
import Product from "features/IllustrationForm/Product";
import Select from "components/Select";
import Distributions from "features/IllustrationForm/Distribution";
import IssueDate from "features/IllustrationForm/IssueDate";

import {
  needOptions,
  pricingTableOptions,
  rateOptions,
  typeOptions,
  leftColSize,
  rightColSize,
  productOptions,
} from "features/IllustrationForm/constants";

function StepScenario() {
  const { handleChange, values, setFieldValue, touched, errors } = useFormikContext();
  const isPPVA = values.product === productOptions.PPVA;

  /**
   * @param {number} rateNum
   * @returns {string}
   */
  function formatRateText(rateNum) {
    return `${rateNum.toFixed(2)} %`;
  }

  return (
    <fieldset className="input-options">
      <Row>
        <Col>
          <h2 className="form-style-heading">Input Options</h2>
        </Col>
      </Row>

      <Form.Group as={Row} controlId="selectType" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Type
        </Form.Label>
        <Col className={rightColSize}>
          <Select
            initial="select a type"
            options={typeOptions}
            name="type"
            onChange={handleChange}
            value={values.type}
            isInvalid={touched.type && !!errors.type}
          />
          <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="need" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Need
        </Form.Label>
        <Col className={rightColSize}>
          <Select
            initial="select one"
            options={needOptions}
            name="need"
            as="select"
            onChange={handleChange}
            disabled={isPPVA}
            value={values.need}
            isInvalid={touched.need && !!errors.need}
          />
          <Form.Control.Feedback type="invalid">{errors.need}</Form.Control.Feedback>
          {isPPVA && (
            <Form.Text>
              For PPVA, need must be set to "Charity"
            </Form.Text>
          )}
        </Col>
      </Form.Group>

      <Product />
      <IssueDate />
      <Exchange />
      <Owner />
      <Insured />
      <Amount />
      <Producer />

      <Form.Group as={Row} controlId="pricingTable" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Pricing Table
        </Form.Label>
        <Col className={rightColSize}>
          <Select
            options={pricingTableOptions}
            name="pricingTable"
            onChange={handleChange}
            value={values.pricingTable}
            isInvalid={touched.pricingTable && !!errors.pricingTable}
          />
          <Form.Control.Feedback type="invalid">{errors.pricingTable}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        controlId="taxRate"
        className={cn("align-items-center", {
          "is-invalid": touched.taxRate && !!errors.taxRate,
        })}
      >
        <Form.Label column className={leftColSize}>
          Tax rate
        </Form.Label>
        <Col className={rightColSize}>
          <InputGroup>
            <Form.Control
              type="number"
              inputMode="numeric"
              min="20"
              max="70"
              step="1"
              name="taxRate"
              value={values.taxRate || 37}
              onChange={handleChange}
              isInvalid={touched.taxRate && !!errors.taxRate}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">{errors.taxRate}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="rateOfReturn" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Rate of return
        </Form.Label>
        <Col className={rightColSize}>
          <Select
            as="select"
            formatText={formatRateText}
            name="rateOfReturn"
            options={rateOptions}
            onChange={(e) => {
              setFieldValue("rateOfReturn", parseFloat(e.target.value));
            }}
            value={values.rateOfReturn || 4}
          />
        </Col>
      </Form.Group>

      <Distributions />
    </fieldset>
  );
}

export default StepScenario;
