import { matchSorter } from 'match-sorter';

/**
 * filter array removing any falsey value. I know it says "null" but really it's anything falsey
 * @param {any[]} arr
 * @returns new array
 */
export const filterNull = (arr) => arr.filter((item) => !!item);

/**
 * Add up all of the numbers in an array of only numbers
 * @param {number[]} arr 
 * @returns sum total of array
 */
export const sumArr = (arr = []) => arr.reduce((acc, val) => val + acc, 0);

/**
 * filters an array of objects containing the properties 'firstName' and 'lastName' using
 * matchSorter
 * @param {string} term
 * @param {object[]} list
 * @returns
 */
 export function filterByFullName(term = '', list) {
  if (term.trim() === '') return list;
  return matchSorter(list, term, {
    keys: [
      {
        key: 'firstName',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
      {
        key: 'lastName',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
    ],
  });
}

/**
 * filters an array of objects containing the properties 'firstName' and 'lastName' using
 * matchSorter
 * @param {string} term
 * @param {object[]} list
 * @returns
 */
export function filterByName(term = '', list) {
  if (term.trim() === '') return list;
  return matchSorter(list, term, {
    keys: [
      {
        key: 'name',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
    ],
  });
}

/**
 * moves a single item in an array to another index of the array
 * @param {any[]} arr 
 * @param {number} fromIndex 
 * @param {number} toIndex 
 */
export function move(arr, fromIndex, toIndex) {
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
}