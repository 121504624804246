import Container from 'react-bootstrap/Container';
import Header from 'components/Header';
import useGlobalAlert from 'hooks/useGlobalAlert';
import Alert from 'react-bootstrap/Alert';
// import { autoLogout } from 'components/api-authorization/autoLogout';

function Layout({ children = [] }) {
  const { alert, removeAlert } = useGlobalAlert();

  let { message, status, type } = alert || {};
  if (status === 401) {
    // autoLogout().catch(console.error);
    type = "danger";
    message = "An authentication error occured, to fix it you must logout and log back in."
  }

  return (
    <Container className="app h-100">
      <Header />
      {alert && (
        <Alert variant={type}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              removeAlert();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {message}
        </Alert>
      )}
      {children}
    </Container>
  );
}

export default Layout;
