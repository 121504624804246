import { filterNull } from "utils/array";
import { healthOptions, productOptions } from "features/IllustrationForm/constants";

/**
 * This converts large numbers to short strings.
 * examples: 1235 -> 1K, 6271235 -> 6.2M, 93246571235 -> 93B, 1935 -> 2K (it rounds thousands)
 * @param {number} num
 * @returns {string}
 */
export function shortCurrency(num) {
  if (num > 999_999_999) {
    return String(num).replace(/,?[0-9]{3},?[0-9]{3},?[0-9]{3}$/, "B");
  } else if (num > 999_999) {
    const mil = String(num);
    // 13_400_000 -> 134, or 10_000_000 -> 100
    const short = mil.replace(/[0-9]{2}[0-9]{3}$/, "");
    const fraction  = short.slice(-1);
    const whole = short.slice(0, -1);
    if (fraction && fraction !== "0") {
      return `${whole}.${fraction}M`
    } else {
      return `${whole}M`
    }
  } else if (num > 999) {
    const roundedK = Math.round(num/1000)*1000;
    return String(roundedK).replace(/,?[0-9]{3}$/, "K");
  } else {
    return String(num);
  }
}

/**
 *
 * @param {Scenario} scenario
 * @returns {string}
 */
function makeInsuredName(scenario, skipSmoker = false) {
  const { insured, age, gender, product } = scenario;
  if (product === productOptions.PPVA) {
    return `${age}${gender.charAt(0)}`.toLowerCase();
  }
  if (!Array.isArray(insured)) return "";

  // AGE+gender+smoker+health
  return insured
    .map((ins) => {
      const smoker = ins.smoker ? "y" : "n";
      const gender = ins.gender.charAt(0);
      let health;
      switch (ins.health) {
        case healthOptions.PREFERRED:
          health = "p";
          break;
        case healthOptions.STANDARD:
          health = "s";
          break;
        default:
          health = ins.health.replace("%", "");
      }
      if (skipSmoker) {
        return `${ins.age}${gender}${health}`.toLowerCase();
      }
      return `${ins.age}${gender}${smoker}${health}`.toLowerCase();
    })
    .join("-");
}

function makePayment(total, pays) {
  // $10M3p - 10 million, 3 payments
  const prettyAmount = shortCurrency(total);
  return `$${prettyAmount}${pays}p`;
}

/**
 * this creates the name for the scenario select
 * @param {Scenario} scenarioData
 * @returns
 */
export function makeScenarioName(scenarioData) {
  const parts = [];

  parts.push(scenarioData.product.toUpperCase());

  if (scenarioData.hasDistributions) {
    parts.push("dist");
  }

  const insured = makeInsuredName(scenarioData);
  if (insured) {
    parts.push(insured);
  }

  parts.push(makePayment(scenarioData.totalAmount, scenarioData.numOfPayments));

  parts.push(
    scenarioData.taxRate,
    scenarioData.rateOfReturn,
    scenarioData.pricingTable.toLowerCase(),
    scenarioData.type.toLowerCase()
  );

  return parts.join("-").replace(/ /g, "-");
}

/**
 * This creates the 20 character limit name used by ingenius
 * @param {Object} scenarioData
 * @returns
 */
export function makeQuoteName(scenarioData) {
  // Add d if distributions, round to nearest millions for investment, round to tenths of a
  // percent but only if applicable (don’t show the .0)

  // N,43mp,4M3p,6 (non-MEC, 43 year old male preferred, $4million in 3 payments, 6% return)

  // N,43mp53fp,4M3p,6 (non-MEC, Survivor 43 year old male preferred and a 53 year old female
  // preferred, $4million in 3 payments, 6% return)

  // Nd,43mp53fp,4M3p,6.5 (non-MEC with distributions, Survivor 43 year old male preferred and a
  // 53 year old female preferred, $4million in 3 payments, 6.5% return)

  // M,43mp53fp,4M,6.5 (MEC, Survivor 43 year old male preferred and a 53 year old female
  // preferred, $4million in 3 payments, 6.5% return)

  // M,43mp,4.0M,6 (MEC, 43 year old male preferred, $4million in 3 payments, 6% return)

  // A,43m,4.0M,6 (PPVA, 43 year old male, $4million, 6% return)

  // N, Nd, M, A
  let product = "M"; // for MEC
  if (scenarioData.product === productOptions.NON_MEC) {
    product = "N";
    if (scenarioData.hasDistributions) {
      product = "Nd";
    }
  } else if (scenarioData.product === productOptions.PPVA) {
    product = "A";
  }

  return filterNull([
    product,
    makeInsuredName(scenarioData, true),
    makePayment(scenarioData.totalAmount, scenarioData.numOfPayments),
    scenarioData.rateOfReturn,
  ]).join(",");
}
