import { useState } from 'react';
import _debounce from 'lodash-es/debounce';
import { usePaginatedFirms, useFirmSearch } from 'hooks/useFirms';
import { ListGroup, Form } from 'react-bootstrap';
import SpinnerText from 'components/SpinnerText';
import Paginator from 'components/Paginator';
import FirmRow from 'features/FirmManagement/FirmRow';

function EditFirms() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: searchData } = useFirmSearch(search);
  const {
    data: paginatedData = {
      results: [],
    },
    isLoading,
  } = usePaginatedFirms(page);

  let listData = searchData || paginatedData?.results || [];

  const title = searchData ? `Search results:` : 'Firms';

  function onChange(e) {
    setSearch(e.target.value);
  }
  const debouncedOnChange = _debounce(onChange, 300);

  if (isLoading) {
    return <SpinnerText text="loading firms" />;
  }

  return (
    <div>
      <Form.Group controlId="filterFirms">
        <Form.Label className="sr-only">Search</Form.Label>
        <Form.Control placeholder="search" onChange={debouncedOnChange} />
      </Form.Group>
      <p className="mt-3">
        <b>{title}</b>
      </p>
      {listData.length === 0 && (
        <p>
          <i>none</i>
        </p>
      )}
      <ListGroup as="ul" className="mt-4">
        {listData.map((firm) => (
          <FirmRow key={firm.id} firm={firm} />
        ))}
      </ListGroup>
      {!searchData && paginatedData?.totalPages > 0 && (
        <Paginator
          active={page}
          total={paginatedData.totalPages}
          onClick={(pagenum) => {
            setPage(pagenum);
          }}
        />
      )}
    </div>
  );
}

export default EditFirms;
