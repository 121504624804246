import { useAddFirmAdvisor } from 'hooks/useAdvisors';
import SpinnerText from 'components/SpinnerText';
import AdvisorForm from 'features/AdvisorManagement/AdvisorForm';
import { getErrorMesssage } from 'utils/errors';

function AddAdvisor({ onSaveSuccess, firmId }) {
  const mutation = useAddFirmAdvisor();

  async function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(e.target);
    try {
      const newFirm = await mutation.mutateAsync({
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        phoneNumber: formData.get('phoneNumber'),
        firmId,
      });
      onSaveSuccess(newFirm);
    } catch (e) {}
  }

  let errorMessage = null;
  if (mutation.isError) {
    errorMessage = getErrorMesssage(mutation.error.message);
  }

  return (
    <>
      <AdvisorForm id="add-new-advisor" onSubmit={onSubmit} actionLabel="Add" />
      {mutation.isLoading && <SpinnerText text="Saving new advisor" />}
      {mutation.isSuccess && <div>New advisor saved!</div>}
      {mutation.isError && (
        <div className="text-danger">{errorMessage}</div>
      )}
    </>
  );
}

export default AddAdvisor;
