import { Form, Button, Row, Col } from 'react-bootstrap';
import cn from 'classnames';

const emptyClient = {
  firstName: '',
  lastName: '',
};

function ClientForm({ client = emptyClient, onSubmit, onCancel, actionLabel = 'save', ...props }) {
  return (
    <Form {...props} onSubmit={onSubmit}>
      <Row>
        <Col xs="12" md="6">
          <Form.Group controlId="clientFirstName">
            <Form.Label className="required">First Name/Initial Identifier</Form.Label>
            <Form.Control name="firstName" required defaultValue={client.firstName} />
          </Form.Group>
        </Col>
        <Col xs="12" md="6">
          <Form.Group controlId="clientLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control name="lastName" defaultValue={client.lastName} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" md="6">
          <Form.Group controlId="clientEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" inputMode="email" name="email" defaultValue={client.email} />
          </Form.Group>
        </Col>
        <Col xs="12" md="6">
          <Form.Group controlId="clientPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              inputMode="tel"
              name="phoneNumber"
              defaultValue={client.phoneNumber}
            />
          </Form.Group>
        </Col>
      </Row>

      <div
        className={cn(
          'mt-3 d-flex',
          { 'justify-content-between': !!onCancel },
          { 'justify-content-end': !onCancel }
        )}
      >
        {onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit">{actionLabel}</Button>
      </div>
    </Form>
  );
}

export default ClientForm;
