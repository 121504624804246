export function formatCurrency(num, maximumFractionDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
  }).format(num);
}

/**
 * Strips away any non-number from a string. Using it mainly to remove currency formatting.
 * It will strip away .00 cents (essentially always rounding down)
 */
export function unformatCurrency(str = '') {
  const numString = str
    .split('.')
    .shift()
    .replace(/[^0-9]/g, '');
  if (numString) {
    return parseInt(numString, 10);
  }
  return 0;
}
