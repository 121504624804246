import { useState } from 'react';
import { useFormikContext } from 'formik';
import SelectComboBox from 'react-select';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';

import { capFirst } from 'utils/string';
import { useFirms } from 'hooks/useFirms';
import ManagementModal from 'components/ManagementModal/Modal';
import AddFirm from 'features/FirmManagement/AddFirm';
import EditFirms from 'features/FirmManagement/EditFirms';

function makeOption(opt) {
  return { value: opt.name, label: opt.name, id: opt.id };
}

function FirmStep() {
  const { setFieldValue, errors, touched, setFieldTouched, values } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const stepName = 'firm';
  const stepId = `${stepName}Id`;
  const controlError = errors[stepName];
  const controlTouched = touched[stepName];
  const { data = [], error, isLoading } = useFirms();

  let selectedValue = data.find((opt) => opt.id === values[stepId]) || '';
  if (typeof selectedValue === 'object') {
    selectedValue = makeOption(selectedValue);
  }

  function updateValues(name, id) {
    setFieldValue(stepName, name);
    setFieldValue(stepId, id);

    // reset advisor and client if we change the firm id
    setFieldValue('advisor', '');
    setFieldValue('advisorId', null);
    setFieldValue('client', '');
    setFieldValue('clientId', null);
  }

  function handleChange(value) {
    updateValues(value?.value || '', value?.id || '');
    setTimeout(() => setFieldTouched(stepName, true));
  }

  return (
    <>
      {error && (
        <Alert variant="danger">
          API error fetching {stepName}s: {error.message}
        </Alert>
      )}
  
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <div className="d-flex justify-content-between align-items-center my-2">
              <label htmlFor="firmSelect" className="form-label m-0">
                {capFirst(stepName)}
              </label>
              <Button
                size="sm"
                className="ml-3 mr-3 rounded-pill pill-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                manage firms
              </Button>
            </div>
            <SelectComboBox
              id="firmSelect"
              isLoading={isLoading}
              isClearable
              isSearchable
              aria-invalid={controlTouched && !!controlError}
              onChange={handleChange}
              value={selectedValue}
              isOptionSelected={(option, selectValue) =>
                selectValue.some((i) => i.id === option.id)
              }
              options={data.map(makeOption)}
            />
            {controlTouched && controlError && (
              <Form.Control.Feedback className="d-block mb-3" type="invalid">
                {controlError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <ManagementModal
        id="manage-firms-modal"
        title="Manage Firms"
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="p-3 bg-light mb-3">
          <AddFirm
            onSaveSuccess={(newFirm) => {
              updateValues(newFirm.name, newFirm.id);
              setShowModal(false);
            }}
          />
        </div>
        <EditFirms />
      </ManagementModal>
    </>
  );
}

export default FirmStep;
