import { useQuery, useMutation, useQueryClient } from 'react-query';
import { post, get, put, del } from 'utils/fetch';
import { ADVISORS, CLIENTS, SCENARIOS, FIRMS } from 'hooks/constants';

const cacheKey = ADVISORS.CACHE_KEY;
const endpoint = ADVISORS.ENDPOINT;

/**
 * Get advisors for a specific firm
 * @param {string} firmId uuid of firm
 * @returns {Promise<Advisor[]>}
 */
export function useFirmAdvisors(firmId) {
  return useQuery([cacheKey, firmId], () => get(`${FIRMS.ENDPOINT}/${firmId}/advisors`), {
    enabled: !!firmId,
  });
}

/**
 * Return a paginated list of Advisors for a specific firm
 * @param {string} firmId
 * @param {number} page
 * @returns {Promise<PaginatedFirm>}
 */
export function usePaginatedAdvisors(firmId, page = 1) {
  return useQuery(
    [cacheKey, firmId, { page }],
    () => get(`${endpoint}/paginated?firmId=${firmId}&page=${page}`),
    {
      keepPreviousData: true,
      enabled: !!firmId,
    }
  );
}

/**
 * Filter firms using search term
 * @returns {Promise<Firm[]>}
 */
 export function useAdvisorSearch(firmId, searchString) {
  return useQuery([cacheKey, firmId, { searchString }], () => get(`${endpoint}?firmId=${firmId}&search=${searchString}`), {
    enabled: !!searchString && !!firmId,
  });
}

export function useAddFirmAdvisor() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      return post(endpoint, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );
}

export function useUpdateAdvisor() {
  const queryClient = useQueryClient();

  return useMutation((data) => put(endpoint, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKey);
    },
  });
}

export function useDeleteAdvisor() {
  const queryClient = useQueryClient();

  return useMutation((id) => del(`${endpoint}/${id}`), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(cacheKey);
        // when we delete an advisor it should cascade and delete all related clients and scenarios,
        // so we'll need to invalidate those caches as well. The timeout is to let an animation
        // finish
        queryClient.invalidateQueries(CLIENTS.CACHE_KEY);
        queryClient.invalidateQueries(SCENARIOS.CACHE_KEY);
      }, 1000);
    },
  });
}