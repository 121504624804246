import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, put } from 'utils/fetch';
import { FACTORS } from 'hooks/constants';

const cacheKey = FACTORS.CACHE_KEY;
const endpoint = FACTORS.ENDPOINT;

/**
 * @typedef {Object} Factor
 * @property {number} factor1_Lives1
 * @property {number} factor1_Lives2
 * @property {number} factor2
 * @property {number} id
 * @property {number} rangeMax
 * @property {number} rangeMin
 */

/**
 * Get all of the Factors in our db
 * @returns {Factor[]}
 */
export function useFactors() {
  return useQuery(cacheKey, () => get(endpoint), {
		// factors are barely going to change so we cache them for a very long time to prevent
		// to many round trips to the api and db
    cacheTime: 2 * 60 * 60 * 1000, // 2 hour
    staleTime: 60 * 60 * 1000, // 1 hour
  });
}

/**
 * Update all factors at once
 */
export function useUpdateFactos() {
  const queryClient = useQueryClient();

  return useMutation((data) => put(endpoint, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKey);
    },
  });
}