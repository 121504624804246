import { useState } from 'react';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { authedFetch } from 'utils/fetch';

function LegacyForm() {
  const [submitError, setSubmitError] = useState(null);

  const initialValues = {
    file: null,
    sampleFile: '',
    incomeTaxRate: 37,
    investmentTaxRate: 35,
    financialAdvisor: 'Financial Advisor',
    preparedFor: '',
    includeCoverPage: false,
    includeGraphics: false,
    includeLedger: false,
    staticTemplate: 'none',
    branding: '3CStructures',
  };

  const validate = (values) => {
    const errors = {};

    // either a file needs to be selected or a sample file needs to be chosen
    if (!values.file) {
      if (!values.sampleFile || values.sampleFile === 'none') {
        errors.sampleFile = 'You must select a file to upload or select a sample file';
        errors.file = ' ';
      }
    }

    if (!values.incomeTaxRate) {
      errors.incomeTaxRate = 'Income tax rate required';
    }
    if (!values.investmentTaxRate) {
      errors.investmentTaxRate = 'Investment tax rate required';
    }

    if (!values.financialAdvisor) {
      errors.financialAdvisor = 'Financial advisor required';
    }

    return errors;
  };

  const handleSubmit = async (values) => {
    // console.log('SUBMIT', values);
    try {
      var data = new FormData();
      Object.keys(values).forEach((key) => {
        data.append(key, values[key]);
      });
      const response = await authedFetch('/api/generate', {
        method: 'POST',
        body: data,
      });
      const json = await response.json();
      // console.log('RESPONSE', json);
      window.open(json.pdfLocation.replace('wwwroot', ''), '_blank');
    } catch (err) {
      console.error(err);
      setSubmitError(err.message);
    }
  };

  return (
    <>
      <Formik validate={validate} onSubmit={handleSubmit} initialValues={initialValues}>
        {(props) => <LegacyFormContent {...props} />}
      </Formik>
      {!!submitError && <p className="text-danger">{submitError}</p>}
    </>
  );
}

function LegacyFormContent({
  handleSubmit,
  handleChange,
  values,
  errors,
  isSubmitting,
  setFieldValue,
}) {
  const leftColSize = 4;
  const rightColSize = 8;
  const [fileName, setFileName] = useState('Choose a file to upload');

  function handleFile(e) {
    const [file] = e.currentTarget.files;
    if (file) {
      setFieldValue('file', file);
      setFileName(file.name);
    }
  }

  return (
    <form
      noValidate
      id="mainForm"
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit}
    >
      <h2 className="form-style-heading">Inputs</h2>

      <Row className="mb-3">
        <Col sm={leftColSize}>
          <b>Excel</b>
        </Col>
        <Col sm={rightColSize} className='bg-light p-4'>
          <Form.Group controlId="file" className="mb-3">
            <Form.Label className="sr-only">Upload Excel</Form.Label>
            <div className="custom-file">
              <FormControl
                type="file"
                name="file"
                className="custom-file-input"
                accept=".xlsx, .xls"
                onChange={handleFile}
                isInvalid={!!errors.file}
              />
              <p className="custom-file-label">{fileName}</p>
            </div>
          </Form.Group>
          <p className='text-center'><b>or</b></p>
          <Form.Group controlId="sampleFile">
            <Form.Label className="sr-only">Choose a Sample File</Form.Label>
            <FormControl
              as="select"
              name="sampleFile"
              onChange={handleChange}
              value={values.sampleFile}
              isInvalid={!!errors.sampleFile}
            >
              <option disabled value="">
                Choose a sample File
              </option>
              <option value="Sam_PPVA(direct Annuity charity PreTax).xlsx">
                Annuity (Charity/PreTax)
              </option>
              <option value="MNS50_PPVA(2019-10-21T04-26-15).xlsx">Annuity</option>
              <option value="MNS50_MEC(2019-10-03T15-51-26).xlsx">MEC</option>
              <option value="MNS50_NonMEC(2019-10-03T16-04-13).xlsx">NonMEC</option>
              <option value="MNS50 PRF $39.2m Non-MEC B to A 6% Base N-Gtd (Distributions) (2).xlsx">
                NonMEC (distributions)
              </option>
            </FormControl>
            <Form.Control.Feedback type="invalid">{errors.sampleFile}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group as={Row} controlId="incomeTaxRate" className="align-items-center mb-3">
        <Form.Label column xs={leftColSize} className="required">
          Investment Tax Rate
        </Form.Label>
        <Col sm="3">
          <InputGroup>
            <FormControl
              required
              type="number"
              inputMode="numeric"
              onChange={handleChange}
              name="incomeTaxRate"
              value={values.incomeTaxRate}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="investmentTaxRate" className="align-items-center mb-3">
        <Form.Label column xs={leftColSize} className="required">
          Income Tax Rate
        </Form.Label>
        <Col sm="3">
          <InputGroup>
            <FormControl
              required
              type="number"
              inputMode="numeric"
              onChange={handleChange}
              name="investmentTaxRate"
              value={values.investmentTaxRate}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="financialAdvisor" className="mb-3">
        <Form.Label column sm={leftColSize}>
          Financial Advisor
        </Form.Label>
        <Col sm={rightColSize}>
          <FormControl
            required
            onChange={handleChange}
            name="financialAdvisor"
            value={values.financialAdvisor}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="preparedFor" className="mb-3">
        <Form.Label column sm={leftColSize}>
          Prepared For
        </Form.Label>
        <Col sm={rightColSize}>
          <FormControl onChange={handleChange} name="preparedFor" value={values.preparedFor} />
        </Col>
      </Form.Group>

      <h2 className="form-style-heading">Output Options</h2>

      <Form.Group as={Row} controlId="includeCoverPage" className="mb-3 align-items-center">
        <Form.Label column sm={leftColSize}>
          Cover Page
        </Form.Label>
        <Col sm={rightColSize}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeCoverPage"
            value={values.includeCoverPage}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="includeGraphics" className="mb-3 align-items-center">
        <Form.Label column sm={leftColSize}>
          Graphics
        </Form.Label>
        <Col sm={rightColSize}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeGraphics"
            value={values.includeGraphics}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="includeLedger" className="mb-3 align-items-center">
        <Form.Label column sm={leftColSize}>
          Ledger
        </Form.Label>
        <Col sm={rightColSize}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeLedger"
            value={values.includeLedger}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="staticTemplate" className="mb-3">
        <Form.Label column sm={leftColSize}>
          Intro/Summary Template
        </Form.Label>
        <Col sm={rightColSize}>
          <FormControl
            as="select"
            name="staticTemplate"
            onChange={handleChange}
            value={values.staticTemplate}
          >
            <option value="3CStructures">3CStructures</option>
            <option value="none">none</option>
          </FormControl>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="branding" className="mb-3">
        <Form.Label column sm={leftColSize}>
          Branding
        </Form.Label>
        <Col sm={rightColSize}>
          <FormControl as="select" name="branding" onChange={handleChange} value={values.branding}>
            <option value="3CStructures">3CStructures</option>
            <option value="none">none</option>
          </FormControl>
        </Col>
      </Form.Group>

      <Row>
        <Col sm={leftColSize} />
        <Col sm={rightColSize}>
          <Button type="submit" variant="primary" value="Generate PDF" disabled={isSubmitting}>
            Generate PDF
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default LegacyForm;
