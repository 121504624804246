import { FieldArray, useFormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  genderOptions,
  healthOptions,
  leftColNum,
  productOptions,
  rightColNum,
  emptyInsured,
} from "features/IllustrationForm/constants";
import Icon from "components/Icon";

function Insured() {
  const { handleChange, values, touched, errors, setFieldValue } = useFormikContext();

  const insured = values.insured || [];

  /**
   * @param {number} index
   */
  const handleCopyOwner = (index) => {
    // TODO set name of first insured to the owner of the policy
    setFieldValue(`insured[${index}].age`, values.age, false);
    setFieldValue(`insured[${index}].gender`, values.gender, false);
    setFieldValue(`insured[${index}].firstName`, values.clientFirstName, false);
    setFieldValue(`insured[${index}].lastName`, values.clientLastName, false);
  };

  if (!values.product || values.product === productOptions.PPVA) {
    // The Insured section should not show if product is a PPVA
    return null;
  }

  return (
    <FieldArray
      name="insured"
      render={({ push, remove }) => (
        <>
          <Row>
            <Col className={leftColNum}>
              <h3 className="h6 font-weight-bold">Insured</h3>
            </Col>
            <Col sm={rightColNum}>
              <Row>
                <Col sm="12" md="6" lg="4">
                  <Button
                    size="sm"
                    disabled={insured.length >= 3}
                    onClick={() => {
                      push(emptyInsured);
                    }}
                  >
                    <Icon name="plus-lg" className="d-inline-block mr-2" />
                    {insured.length >= 3 ? "max 3" : "add insured"}
                  </Button>
                </Col>
              </Row>

              {typeof errors.insured === "string" && (
                <span className="invalid-feedback d-block">{errors.insured}</span>
              )}
              {insured.map((ins, index) => {
                const currentTouched = touched.insured?.[index];
                const currentError = errors.insured?.[index];
                return (
                  <Row key={index} className="mb-3 mt-3 insuredRow">
                    <Col xs="12" className="mb-1 d-flex align-items-center justify-content-between">
                      <Button variant="link" size="sm" onClick={() => handleCopyOwner(index)}>
                        copy owner details
                      </Button>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group controlId={`insuredFirstName${index}`}>
                        <Form.Label className="sr-only">First Name/ID</Form.Label>
                        <Form.Control
                          placeholder="First Name/ID"
                          name={`insured.${index}.firstName`}
                          value={ins.firstName}
                          onChange={handleChange}
                          isInvalid={currentTouched?.firstName && !!currentError?.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {currentError?.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group controlId={`insuredLastName${index}`}>
                        <Form.Label className="sr-only">Last Name</Form.Label>
                        <Form.Control
                          placeholder="Last Name"
                          name={`insured.${index}.lastName`}
                          value={ins.lastName}
                          onChange={handleChange}
                          isInvalid={currentTouched?.lastName && !!currentError?.lastName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {currentError?.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={12}
                      className="d-flex align-items-center justify-content-between flex-wrap"
                    >
                      <Form.Group controlId={`insuredAge${index}`} className="mb-3 mb-md-0">
                        <Form.Label className="sr-only">Age</Form.Label>
                        <Form.Control
                          placeholder="Age"
                          type="number"
                          inputMode="numeric"
                          min="0"
                          name={`insured.${index}.age`}
                          value={ins.age}
                          onChange={handleChange}
                          isInvalid={currentTouched?.age && !!currentError?.age}
                        />
                        <Form.Control.Feedback type="invalid">
                          {currentError?.age}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId={`insuredGender${index}`} className="mb-3 mb-md-0">
                        <Form.Label className="sr-only">Gender</Form.Label>
                        <Form.Control
                          as="select"
                          name={`insured.${index}.gender`}
                          value={ins.gender}
                          onChange={handleChange}
                          isInvalid={currentTouched?.gender && !!currentError?.gender}
                        >
                          <option disabled value="">
                            Gender
                          </option>
                          {Object.keys(genderOptions).map((key) => (
                            <option key={key} value={key}>
                              {genderOptions[key]}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {currentError?.gender}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId={`insuredSmoker${index}`}>
                        <Form.Check
                          inline
                          label="Smoker"
                          type="checkbox"
                          name={`insured.${index}.smoker`}
                          checked={ins.smoker}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group controlId={`insuredHealth${index}`}>
                        <Form.Label className="sr-only">Health</Form.Label>
                        <Form.Control
                          as="select"
                          name={`insured.${index}.health`}
                          value={ins.health}
                          onChange={handleChange}
                          isInvalid={currentTouched?.health && !!currentError?.health}
                        >
                          <option disabled value="">
                            Health
                          </option>
                          {Object.values(healthOptions).map((h) => (
                            <option key={h} value={h}>
                              {h}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {currentError?.health}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button onClick={() => remove(index)}>
                        <Icon name="trash" />
                        <span className="sr-only">remove</span>
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </>
      )}
    />
  );
}

export default Insured;
