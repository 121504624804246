import { useAddFirm } from 'hooks/useFirms';
import SpinnerText from 'components/SpinnerText';
import FirmForm from 'features/FirmManagement/FirmForm';
import { getErrorMesssage } from 'utils/errors';

function ManageFirms({ onSaveSuccess }) {
  const mutation = useAddFirm();

  async function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    try {
      const newFirm = await mutation.mutateAsync({ name: formData.get('name') });
      onSaveSuccess(newFirm);
    } catch (e) {
      // error handled by mutation
    }
  }

  let errorMessage = null;
  if (mutation.isError) {
    errorMessage = getErrorMesssage(mutation.error.message);
  }

  return (
    <>
      <FirmForm id="add-new-firm" onSubmit={onSubmit} actionLabel="Add" />
      {mutation.isLoading && <SpinnerText text="Saving new firm" />}
      {mutation.isSuccess && <div>New firm saved!</div>}
      {mutation.isError && (
        <div className="text-danger">{errorMessage}</div>
      )}
    </>
  );
}

export default ManageFirms;
