import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from 'components/Layout';

function NoMatch() {
  return (
    <Layout>
      <Row>
        <Col>
          <p>Page does not exist</p>
        </Col>
      </Row>
    </Layout>
  );
}

export default NoMatch;
