import authService from 'components/api-authorization/AuthorizeService';
import _merge from 'lodash-es/merge';

function enhanceError(err, response) {
  err.response = {
    status: response.status,
    ok: response.ok,
    headers: response.headers,
    statusText: response.statusText,
    url: response.url,
  };
  return err;
}

export async function authedFetch(path, options = {}) {
  const token = await authService.getAccessToken();

  const defaultOptions = {
    headers: !token ? {} : { Authorization: `Bearer ${token}` },
    method: 'GET',
    mode: 'cors',
  };

  const mergedOptions = _merge(defaultOptions, options);

  const response = await fetch(path, mergedOptions);

  if (!response.ok) {
    // native fetch does not throw Errors for any HTTP statuses (4xx and 5xx) so here we mimic axios
    // by throwing an error for anything that is not a 2xx status, and adding more data to the Error
    const errorMessage = await response.text().catch(console.error);
    const msg = errorMessage || `${response.status} ${response.statusText}`;
    const apierror = new Error(msg);
    throw enhanceError(apierror, response);
  }

  return response;
}

/*****************************************************************/

export async function get(endpoint) {
  const response = await authedFetch(endpoint);
  return await response.json();
}

export async function post(endpoint, data) {
  const response = await authedFetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return await response.json();
}

/**
 * The API will return either a 204 (for successful update) or a 404
 * @param {string} endpoint
 * @param {object} data
 * @returns {Promise<boolean>}
 */
export async function put(endpoint, data) {
  const response = await authedFetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.ok;
}

/**
 * The API will return either a 204 (for successful delete) or a 404
 * @param {string} endpoint
 * @returns {Promise<boolean>}
 */
export async function del(endpoint) {
  const response = await authedFetch(endpoint, {
    method: 'DELETE',
  });
  return response.ok;
}
