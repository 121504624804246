import { Pagination } from 'react-bootstrap';

function Paginator({ id, active = 1, total = 0, onClick = () => {}, showPrevNext = false }) {
  let items = [];

  if (total <= 1) return null;

  if (showPrevNext) {
    items.push(
      <Pagination.Prev
        key={`${id}-pagination-prev`}
        disabled={active <= 1}
        onClick={(e) => {
          e.preventDefault();
          onClick(active - 1);
        }}
      />
    );
  }

  for (let number = 1; number <= total; number++) {
    items.push(
      <Pagination.Item
        key={`${id}-pagination-${number}`}
        active={number === active}
        activeLabel=""
        onClick={(e) => {
          e.preventDefault();
          onClick(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (showPrevNext) {
    items.push(
      <Pagination.Next
        key={`${id}-pagination-next`}
        disabled={active >= total}
        onClick={(e) => {
          e.preventDefault();
          onClick(active + 1);
        }}
      />
    );
  }

  return <Pagination className="mt-3">{items}</Pagination>;
}

export default Paginator;
