import { Form, Button, Row, Col } from 'react-bootstrap';
import cn from 'classnames';

const emptyAdvisor = {
  firstName: '',
  lastName: '',
};

function AdvisorForm({
  advisor = emptyAdvisor,
  onSubmit,
  onCancel,
  actionLabel = 'save',
  ...props
}) {
  return (
    <Form {...props} onSubmit={onSubmit}>
      <Row>
        <Col xs="12" md="6">
          <Form.Group controlId="advisorFirstName">
            <Form.Label className="required">First Name</Form.Label>
            <Form.Control name="firstName" required defaultValue={advisor.firstName} />
          </Form.Group>
        </Col>
        <Col xs="12" md="6">
          <Form.Group controlId="advisorLastName">
            <Form.Label className="required">Last Name</Form.Label>
            <Form.Control name="lastName" required defaultValue={advisor.lastName} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" md="6">
          <Form.Group controlId="advisorEmail">
            <Form.Label className="required">Email</Form.Label>
            <Form.Control
              type="email"
              inputMode="email"
              name="email"
              required
              defaultValue={advisor.email}
            />
          </Form.Group>
        </Col>
        <Col xs="12" md="6">
          <Form.Group controlId="advisorPhone">
            <Form.Label className="required">Phone</Form.Label>
            <Form.Control
              type="tel"
              inputMode="tel"
              name="phoneNumber"
              required
              defaultValue={advisor.phoneNumber}
            />
          </Form.Group>
        </Col>
      </Row>

      <div
        className={cn(
          'mt-3 d-flex',
          { 'justify-content-between': !!onCancel },
          { 'justify-content-end': !onCancel }
        )}
      >
        {onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit">{actionLabel}</Button>
      </div>
    </Form>
  );
}

export default AdvisorForm;
