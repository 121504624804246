export const FIRMS = {
  CACHE_KEY: 'firms',
  ENDPOINT: '/api/firm',
};

export const ADVISORS = {
  CACHE_KEY: 'advisors',
  ENDPOINT: '/api/advisor',
};

export const CLIENTS = {
  CACHE_KEY: 'clients',
  ENDPOINT: '/api/client',
};

export const SCENARIOS = {
  CACHE_KEY: 'scenarios',
  ENDPOINT: '/api/scenario',
};

export const FACTORS = {
  CACHE_KEY: 'factors',
  ENDPOINT: '/api/factor',
};

export const PRODUCERS = {
  CACHE_KEY: 'producers',
  ENDPOINT: '/api/producer',
};
