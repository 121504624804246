import FormControl from 'react-bootstrap/FormControl';

function Select({ options, initial, formatText = (x) => x, ...props }) {
  let optionsArr;
  switch (true) {
    case Array.isArray(options):
      optionsArr = options;
      break;
    case typeof options === 'object':
      optionsArr = Object.values(options);
      break;
    default:
      throw new Error('Select options prop must be a flat array or an object');
  }

  return (
    <FormControl as="select" {...props}>
      {initial && (
        <option disabled value="">
          {initial}
        </option>
      )}
      {optionsArr.map((opt) => (
        <option key={opt} value={opt}>
          {formatText(opt)}
        </option>
      ))}
    </FormControl>
  );
}

export default Select;
