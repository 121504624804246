import { useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import Icon from 'components/Icon';
import ConfirmDelete from 'components/ConfirmDelete';
import FirmUpdate from 'features/FirmManagement/FirmUpdate';
import { useDeleteFirm } from 'hooks/useFirms';
import SpinnerText from 'components/SpinnerText';
import cn from 'classnames';

function FirmRow({ firm }) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const mutation = useDeleteFirm();

  return (
    <>
      <ListGroup.Item
        as="li"
        data-firm={firm.id}
        className={cn('d-flex justify-content-between align-items-center', {
          fadeOut: mutation.isSuccess,
        })}
      >
        <div className={cn('mr-auto', { deleted: mutation.isSuccess })}>
          <div className="font-weight-bold">{firm.name}</div>
        </div>
        <div hidden={mutation.isSuccess}>
          <Button
            variant="info"
            className='edit-firm-btn'
            title="edit firm"
            onClick={() => {
              setShowEdit(!showEdit);
            }}
          >
            <Icon name="pencil" />
            <div className="sr-only">Edit Firm</div>
          </Button>

          <Button
            variant="danger"
            title="delete firm"
            className="ml-3 del-firm-btn"
            onClick={() => {
              setShowDelete(!showDelete);
            }}
          >
            <Icon name="trash" />
            <div className="sr-only">Delete Firm</div>
          </Button>
        </div>
      </ListGroup.Item>
      {showDelete && (
        <ListGroup.Item className="bg-secondary">
          <ConfirmDelete
            title={`Delete firm ${firm.name}`}
            entityId={firm.id}
            onCancel={() => {
              setShowDelete(false);
            }}
            onDelete={async () => {
              try {
                await mutation.mutateAsync(firm.id);
                setShowDelete(false);
              } catch (e) {
                // error is going to be part of mutation.error
              }
            }}
          >
            <p>
              <strong>Please confirm that you want to delete this firm?</strong>{' '}
            </p>
            <p>
              This will also delete{' '}
              <b>
                <em>all</em>
              </b>{' '}
              associated Advisors, Clients, and Scenarios. This can not be undone.
            </p>
            {mutation.isLoading && <SpinnerText text="Deleting Firm" />}
            {mutation.isError && (
              <div className="text-danger">An error occurred: {mutation.error.message}</div>
            )}
          </ConfirmDelete>
        </ListGroup.Item>
      )}
      {showEdit && (
        <ListGroup.Item className="bg-secondary">
          <FirmUpdate
            firm={firm}
            onCancel={() => {
              setShowEdit(false);
            }}
            onSuccess={() => {
              setShowEdit(false);
            }}
          />
        </ListGroup.Item>
      )}
    </>
  );
}

export default FirmRow;
