import { useState, useCallback, createContext } from "react";

export const AlertContext = createContext({
  /** @type {{type:Variants, message: string, status: number}|null} */
  alert: null,
  /**
   * @type {function(Variants, string, number): void} Adds a global alert
   */
  addAlert: () => {},
  /**
   * Remove the current active alert. Right now this app only supports 1 global alert at a time
   * TODO: update to support multiple
   */
  removeAlert: () => {},
});

/**
 * For now only supporting having one alert at a time
 */
export default function GlobalAlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  const removeAlert = () => setAlert(null);

  const addAlert = (type, message, status) => setAlert({ message, type, status });

  const contextValue = {
    alert,
    addAlert: useCallback((type, message, status) => addAlert(type, message, status), []),
    removeAlert: useCallback(() => removeAlert(), []),
  };

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
}
