import { useState } from "react";
import { useFormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import _omit from "lodash-es/omit";
import { DateTime } from "luxon";

import Paginator from "components/Paginator";
import Icon from "components/Icon";
import ScenarioDownload from "features/IllustrationForm/ScenarioDownload";
import { usePaginatedScenarios, useDeleteScenario } from "hooks/useScenarios";
import { makeScenarioName } from "features/IllustrationForm/namingConvention";
import { getErrorMesssage } from "utils/errors";
import { productOptions } from "features/IllustrationForm/constants";

const omitList = [
  "firm",
  "firmId",
  "advisor",
  "advisorId",
  "client",
  "clientId",
  "id",
  "created",
  "createdBy",
  "excelDownloadId",
  "isDeleted",
  "modified",
  "issueDate",
];

function ScenarioSelect() {
  const [page, setPage] = useState(1);
  const { setValues, values } = useFormikContext();
  const { data, error, isFetched, isLoading } = usePaginatedScenarios(values.clientId, page);
  const mutation = useDeleteScenario();

  const { results = [], totalPages } = data || {};

  function formatDate(datetime) {
    return DateTime.fromISO(`${datetime}Z`).toLocaleString(DateTime.DATETIME_SHORT);
  }

  function loadScenario(id) {
    const scenario = results.find((d) => d.id === id);
    if (scenario) {
      const filteredScenario = _omit(scenario, omitList);
      if (filteredScenario.product === "NON-MEC") {
        filteredScenario.product = productOptions.NON_MEC;
      }
      setValues(
        {
          ...values,
          ...filteredScenario,
          birthdate: scenario.birthdate?.replace(/T.*$/, "") || "",
        },
        false
      );
    }
  }

  async function deleteScenario(id) {
    const shouldDelete = window.confirm(
      "This will permanently delete this scenario and its associted files, are you sure?"
    );
    if (!shouldDelete) return;
    try {
      await mutation.mutateAsync({ scenarioId: id, clientId: values.clientId });
      if (results.length === 0 && page > 1) {
        setPage(page - 1);
      }
    } catch (e) {}
  }

  if (isLoading) {
    return (
      <>
        <Spinner animation="border" size="sm" />
        <span className="d-inline-block ml-2">Loading client scenarios</span>
      </>
    );
  }

  if (!isFetched) return null;

  return (
    <>
      {error && (
        <Alert variant="danger">
          API error fetching client scenarios: {getErrorMesssage(error.message)}
        </Alert>
      )}

      {mutation.isError && (
        <Alert variant="danger">{getErrorMesssage(mutation.error.message)}</Alert>
      )}

      {!results.length && (
        <Row>
          <Col>
            <p>
              <i>This client has no existing scenarios</i>
            </p>
          </Col>
        </Row>
      )}

      {!!results.length && (
        <Row>
          <Col>
            <ul className="list-unstyled">
              {results.map((scenario) => (
                <li
                  key={scenario.id}
                  data-scenario={scenario.id}
                  className="client-scenarios row p-2 align-items-center"
                >
                  <Col xs="auto">
                    <Button
                      size="sm"
                      className="mr-2"
                      onClick={() => {
                        loadScenario(scenario.id);
                      }}
                    >
                      select
                    </Button>
                  </Col>
                  <Col xs="auto" lg="3">
                    <p className="pl-xs-2 pl-md-0 m-0">{formatDate(scenario.created)}</p>
                  </Col>
                  <Col xs="7" md="auto" className="text-break">
                    {makeScenarioName(scenario)}
                  </Col>
                  <Col className="d-flex justify-content-end align-items-baseline">
                    <ScenarioDownload scenarioId={scenario.id} />
                    <button
                      disabled={mutation.isLoading}
                      type="button"
                      className="btn-unstyled h4 ml-2 mb-0"
                      title="Delete this scenario"
                      onClick={() => deleteScenario(scenario.id)}
                    >
                      <Icon name="trash" />
                      <span className="sr-only">Delete this scenario</span>
                    </button>
                  </Col>
                </li>
              ))}
            </ul>
            <Paginator
              id="scenarios"
              active={page}
              total={totalPages}
              onClick={setPage}
              showPrevNext={true}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default ScenarioSelect;
