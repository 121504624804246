import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {
  leftColNum,
  leftColSize,
  rightColNum,
  genderOptions,
} from 'features/IllustrationForm/constants';

function getAgeFromDate(bDate) {
  const { years } = DateTime.fromISO(bDate).diffNow('years');
  return Math.floor(Math.abs(years));
}

function OwnerInfo() {
  const { handleChange, setFieldValue, values, touched, errors } = useFormikContext();

  return (
    <>
      <Form.Group as={Row} controlId="owner" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Owner of the policy
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder="Name"
            name="owner"
            value={values.owner}
            onChange={handleChange}
            isInvalid={touched.owner && !!errors.owner}
          />
          <Form.Control.Feedback type="invalid">{errors.owner}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="owner-gender" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Gender
        </Form.Label>
        <Col sm="6" md="4" lg="3">
          <Form.Control
            as="select"
            name="gender"
            value={values.gender || ''}
            onChange={handleChange}
            isInvalid={touched.gender && !!errors.gender}
          >
            <option disabled value="">
              Gender
            </option>
            {Object.keys(genderOptions).map((key) => (
              <option key={key} value={key}>
                {genderOptions[key]}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Row className="align-items-center">
        <Col className={leftColNum}>
          <p>
            <b>Enter age OR birthdate</b>
          </p>
        </Col>
        <Col sm={rightColNum}>
          <Row>
            <Col sm="12" md="4" className="mb-3">
              <Form.Group controlId="owner-age">
                <Form.Label className="sr-only">Age</Form.Label>
                <Form.Control
                  placeholder="Age"
                  type="number"
                  inputMode="numeric"
                  min="1"
                  name="age"
                  value={values.age || ''}
                  onChange={handleChange}
                  isInvalid={touched.age && !!errors.age}
                />
                <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="12" md="5">
              <Form.Group controlId="owner-birthdate">
                <Form.Label className="sr-only">Birthdate</Form.Label>
                <Form.Control
                  type="date"
                  name="birthdate"
                  value={values.birthdate || ''}
                  onChange={handleChange}
                  onBlur={(e) => {
                    const { value } = e.target;
                    // we dont want to calculate date until date input has all of the info
                    if (!/\d{4}-\d{2}-\d{2}/.test(value)) return;
                    const age = getAgeFromDate(value);
                    setFieldValue('age', age, false);
                    // if (values.isOwnerInsured) {
                    //   setFieldValue('insured[0].age', age, false);
                    // }
                  }}
                  isInvalid={touched.birthdate && !!errors.birthdate}
                />
                <Form.Control.Feedback type="invalid">{errors.birthdate}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default OwnerInfo;
