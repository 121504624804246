import { useState } from "react";
import { Formik } from "formik";
import { Alert, Row, Button, Col } from "react-bootstrap";
import _omit from "lodash-es/omit";

import FirmStep from "features/IllustrationForm/Step-Firm";
import AdvisorStep from "features/IllustrationForm/Step-Advisor";
import ClientStep from "features/IllustrationForm/Step-Client";
import ScenarioSelect from "features/IllustrationForm/ScenarioSelect";
import InputOptions from "features/IllustrationForm/Step-Input-Options";
import OutputOptions from "features/IllustrationForm/OutputOptions";

import { initialValues } from "features/IllustrationForm/constants";
import validationSchema from "features/IllustrationForm/validationSchema";
import SpinnerText from "components/SpinnerText";
import { useAddNewScenario } from "hooks/useScenarios";
import { productOptions } from "features/IllustrationForm/constants";
import { makeQuoteName } from "features/IllustrationForm/namingConvention";
import Icon from "components/Icon";
import { getErrorMesssage } from "utils/errors";

function Form() {
  const mutation = useAddNewScenario();
  const [response, setResponse] = useState(null);

  const handleSubmit = async (values) => {
    setResponse(null);
    const omitList = [];

    if (!values.hasDistributions) {
      omitList.push("distributions_amount", "distributions_startAge", "distributions_endAge");
    }

    if (!values.isExchange) {
      omitList.push("policyAge");
    }
    const filteredValues = _omit(values, omitList);

    if (values.product === productOptions.PPVA) {
      // there should be no insured if product is a PPVA
      filteredValues.insured = [];
    }

    const quoteName = makeQuoteName(filteredValues);
    filteredValues.quoteName = quoteName;

    try {
      const json = await mutation.mutateAsync({ clientId: values.clientId, data: filteredValues });
      setResponse(json);
    } catch (e) {}
  };

  let errorMessage = null;
  if (mutation.isError) {
    errorMessage = getErrorMesssage(mutation.error.message);
  }

  return (
    <div style={{ paddingBottom: "100px" }}>
      <Formik
        component={FormContent}
        enableReinitialize={true}
        initialValues={initialValues}
        onReset={() => {
          mutation.reset();
        }}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchema}
      />
      {mutation.isLoading && <SpinnerText size="lg" text="Saving scenario" />}
      {mutation.isError && <div className="text-danger">{errorMessage}</div>}
      {mutation.isSuccess && (
        <Alert variant="success">
          <p>Scenario saved!</p>
          <a
            style={{ color: "#ff0000" }}
            title="Download WIS Illustration"
            className="d-inline-block ml-2 h2 col-2"
            href={response?.pdfLink}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="file-earmark-pdf" />
            <span className="sr-only">Download WIS Illustration</span>
          </a>
          <a
            style={{ color: "#008000" }}
            title="Download Excel"
            className="d-inline-block ml-2 h2 col-2"
            href={response?.excelLink}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="file-earmark-spreadsheet" />
            <span className="sr-only">download excel</span>
          </a>
        </Alert>
      )}
    </div>
  );
}

function FormContent({ handleSubmit, isSubmitting, handleReset, errors, touched, values }) {
  // console.log(`🚀 ~ file: Form.js ~ line 75 ~ FormContent ~ touched`, touched);
  // console.log(`🚀 ~ file: Form.js ~ line 67 ~ FormContent ~ errors`, errors);
  // console.log(`🚀 ~ file: Form.js ~ line 67 ~ FormContent ~ values`, values);
  return (
    <form noValidate id="mainForm" method="post" onSubmit={handleSubmit} className="container pb-5">
      <Row>
        <Col xs="12" lg="4">
          <FirmStep />
        </Col>
        <Col xs="12" lg="4">
          {values.firmId && <AdvisorStep />}
        </Col>
        <Col xs="12" lg="4">
          {values.advisorId && <ClientStep />}
        </Col>
      </Row>
      <ScenarioSelect />
      <InputOptions />
      <OutputOptions />

      <hr className="mt-4 mb-4" />
      <Row className="mt-3">
        <Col md="6">
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            Save new scenario
          </Button>
        </Col>
        <Col md="6" className="mt-3 mt-md-0" onClick={handleReset}>
          <Button>Reset</Button>
        </Col>
      </Row>
    </form>
  );
}

export default Form;
