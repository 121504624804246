import { useAddAdvisorClient } from 'hooks/useClients';
import SpinnerText from 'components/SpinnerText';
import ClientForm from 'features/ClientManagement/ClientForm';
import { getErrorMesssage } from 'utils/errors';

function AddClient({ onSaveSuccess, advisorId }) {
  const mutation = useAddAdvisorClient();

  async function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(e.target);

    try {
      const newClient = await mutation.mutateAsync({
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        phoneNumber: formData.get('phoneNumber'),
        advisorId,
      });
      onSaveSuccess(newClient);
    } catch (e) {}
  }

  let errorMessage = null;
  if (mutation.isError) {
    errorMessage = getErrorMesssage(mutation.error.message);
  }

  return (
    <>
      <ClientForm id="add-new-client" onSubmit={onSubmit} actionLabel="Add" />
      {mutation.isLoading && <SpinnerText text="Saving new client" />}
      {mutation.isSuccess && <div>New client saved!</div>}
      {mutation.isError && (
        <div className="text-danger">{errorMessage}</div>
      )}
    </>
  );
}

export default AddClient;
