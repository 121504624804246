import { DateTime } from 'luxon';

export const pricingTableOptions = {
  DIRECT: 'direct',
  INTERMEDIARY: 'intermediary',
  // COMPLIMENTARY: 'complimentary' // TODO: This option will be dealt with in a future scope
};

export const initialValues = {
  firm: '',
  firmId: null,
  advisor: '',
  advisorId: null,
  client: '',
  clientFirstName: '',
  clientLastName: '',
  clientId: null,

  // 'base live', 'prelim final', 'final'
  type: '',

  // ?? just an open field?
  // status: '',

  // inheritance, income , charity, NA, deferral
  need: '',

  // PPVA, MEC, Non-MEC
  product: '',

  // required, date when policy is issued
  issueDate: DateTime.now().toISODate(),

  // if Non-MEC they can optionally add distributions
  hasDistributions: false,

  // for Non-MEC only
  optionBtoA: true,

  distributions_startAge: '',
  distributions_endAge: 90,
  distributions_amount: '',

  // section 1035X
  isExchange: false,
  policyAge: 7, // min 7

  // default to current client but allows to be any text
  owner: '',
  age: '',
  birthdate: '',
  gender: '',

  // isOwnerInsured: false,
  
  // min 1, max 3, data for insured on the policy
  insured: [{ firstName: '', lastName: '', age: '', gender: '', smoker: false, health: '' }],

  totalAmount: 0,

  numOfPayments: 0,

  // this is the total amount broken down into annual payments
  // it will just be an array of numbers
  annualPays: [],

  // You can select an advisor from the current firm, it will be a drop down
  // but in the end it just saves the first + last as single string
  // you must select from the list, not an open field
  producer: '',

  // direct, intermediary, ...
  pricingTable: pricingTableOptions.DIRECT,

  // 20% - 70%, 1% increments
  taxRate: 37,

  // 3-10 in .25 increments
  rateOfReturn: 6,

  includeCoverPage: false,
  includeGraphics: false,
  includeLedger: false,
  staticTemplate: 'none',
  branding: '3CStructures',
};

export const emptyInsured = { firstName: '', lastName: '', age: '', gender: '', smoker: false, health: '' };

// 4-10 in .25 increments
function makeRateOfReturn() {
  const options = [];
  let value = 3;
  const total = (10 - value) * 4; // this help loop in .25 increments
  for (let i = 0; i <= total; i++) {
    options.push(value);
    value += 0.25;
  }
  return options;
}
export const rateOptions = makeRateOfReturn();

export const typeOptions = {
  BASE_LIVE: 'base live',
  PRELIM_FINAL: 'prelim final',
  FINAL: 'final',
};

// if you make changes or additions to values of this object, you'll also need to update the
// NeedOptions Model in WISOptions.cs
export const needOptions = {
  INHERITANCE: 'Inheritance',
  INCOME: 'Income',
  CHARITY: 'Charity',
  NONE: 'None',
};

export const productOptions = {
  PPVA: 'PPVA',
  MEC: 'MEC',
  NON_MEC: 'Non-MEC',
};

// 20% - 70%, 1% increments
// I'll probably not use this and just a number input with a min/max
export const taxRateOptions = Array(51)
  .fill(0)
  .map((_, i) => i + 20);

export const healthOptions = {
  PREFERRED: 'preferred',
  STANDARD: 'standard',
};
for (let m = 125; m <= 500; m += 25) {
  healthOptions[`MOR_${m}`] = `${m}%`;
}

export const genderOptions = {
  M: 'Male',
  F: 'Female',
};

export function resetValues(setFieldValue, keysToSkip = []) {
  Object.keys(initialValues).forEach((key) => {
    if (keysToSkip.includes(key)) return;
    setFieldValue(key, initialValues[key]);
  });
}

export const templateOptions = {
  COMPANY: '3CStructures',
  NONE: 'none',
};

export const brandingOptions = {
  COMPANY: '3CStructures',
  NONE: 'none',
};

/**
 * Some UI helper constants
 */
export const leftColNum = 4;
export const rightColNum = 8;
export const rightColMdNum = 4;
export const leftColSize = `col-12 col-md-${leftColNum}`;
export const rightColSize = `col-12 col-md-${rightColMdNum}`;
