// src/common/hooks/useAPIError/index.js
import { useContext } from 'react';
import { AlertContext } from 'providers/GlobalAlertProvider';

function useGlobalAlert() {
  const { alert, addAlert, removeAlert } = useContext(AlertContext);
  return { alert, addAlert, removeAlert };
}

export default useGlobalAlert;
