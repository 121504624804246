import { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import authService from 'components/api-authorization/AuthorizeService';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

function AnonymousView({ loginPath, registerPath }) {
  return (
    <>
      <Nav.Item>
        <Nav.Link as={Link} className="text-dark" to={loginPath}>
          Login
        </Nav.Link>
      </Nav.Item>
    </>
  );
}

function AuthenticatedView({ userName, profilePath, logoutPath }) {
  return (
    <>
      <Nav.Item>
        <Nav.Link as={Link} className="text-dark" to={profilePath}>
          {userName}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} className="text-dark" to={logoutPath}>
          Logout
        </Nav.Link>
      </Nav.Item>
    </>
  );
}

function MainNav() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    async function populateState() {
      const [isAuthenticated, user] = await Promise.all([
        authService.isAuthenticated(),
        authService.getUser(),
      ]);
      setIsAuthenticated(isAuthenticated);
      setUserName(user?.name);
    }
    const subscription = authService.subscribe(() => populateState());
    populateState();
    return function cleanup() {
      authService.unsubscribe(subscription);
    };
  }, []);

  let authNav;

  if (!isAuthenticated) {
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;
    authNav = <AnonymousView registerPath={registerPath} loginPath={loginPath} />;
  } else {
    const profilePath = `${ApplicationPaths.Profile}`;
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
    authNav = (
      <AuthenticatedView userName={userName} profilePath={profilePath} logoutPath={logoutPath} />
    );
  }

  return (
    <Navbar bg="light" expand="sm" className="bg-white border-bottom mb-3">
      <Navbar.Brand href="/">WIS</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {isAuthenticated && (
            <>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/legacy-form">
                Legacy form
              </Nav.Link>
            </>
          )}
        </Nav>
        {authNav}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainNav;
