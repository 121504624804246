import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import { Row, Col, Form, Button, Popover, OverlayTrigger } from "react-bootstrap";

import CurrencyInput from "components/CurrencyInput";
import { formatCurrency, unformatCurrency } from "utils/currency";
import { productOptions, rightColSize, leftColNum } from "features/IllustrationForm/constants";
import { calcMax, assureValues } from "features/IllustrationForm/calc-max";
import { useFactors } from "hooks/useFactors";
import useGlobalAlert from "hooks/useGlobalAlert";
import { getStartAge } from "features/IllustrationForm/helpers";

function Distribution() {
  const [maxErrors, setMaxErrors] = useState([]);
  const [minStart, setMinStart] = useState();
  const { addAlert } = useGlobalAlert();
  const { handleChange, values, setFieldValue, touched, errors } = useFormikContext();
  const {
    hasDistributions,
    product,
    distributions_amount,
    distributions_startAge,
    distributions_endAge,
    insured,
    numOfPayments,
    age,
  } = values;
  const { data, error } = useFactors();

  useEffect(() => {
    if (!hasDistributions) return;

    if ((insured || age) && numOfPayments > 0) {
      const min = getStartAge(insured, age, numOfPayments);
      setFieldValue("distributions_startAge", min, false);
      setMinStart(min);
    }
  }, [age, hasDistributions, insured, numOfPayments, setFieldValue]);

  function handleMax(e) {
    e.preventDefault();
    const missing = assureValues(values);
    if (missing.length) {
      setMaxErrors(missing);
      return;
    }
    const max = calcMax(values, data);
    setFieldValue("distributions_amount", max);
  }

  useEffect(() => {
    if (error) {
      addAlert("danger", `Error fetching factors: ${error.message}`, error.response.status);
    }
  }, [addAlert, error]);

  if (!hasDistributions || product !== productOptions.NON_MEC) {
    return null;
  }

  const placement = window.innerWidth <= 768 ? "bottom" : "right";

  const popover = (
    <Popover id="popover-max">
      <Popover.Header as="h3">Calculate Max</Popover.Header>
      <Popover.Body>
        Unable to calculate max because of the following issues:
        <ul className="m-0">
          {maxErrors.map((e) => (
            <li className="m-0" key={e} dangerouslySetInnerHTML={{ __html: e }} />
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="p-3 border bg-light">
      <p className="text-muted">Non-MEC Distribution</p>
      <Form.Group as={Row} controlId="startAge" className="align-items-center mb-3">
        <Form.Label column md={leftColNum}>
          Distribution - Start Age
        </Form.Label>
        <Col className={rightColSize}>
          <Form.Control
            type="number"
            min={minStart}
            inputMode="numeric"
            name="distributions_startAge"
            value={distributions_startAge}
            onChange={handleChange}
            isInvalid={touched.distributions_startAge && !!errors.distributions_startAge}
          />
          {!!minStart && (
            <Form.Text className="text-muted">Recommended start age of {minStart + 7}</Form.Text>
          )}
          <Form.Control.Feedback type="invalid">
            {errors.distributions_startAge}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="endAge" className="align-items-center mb-3">
        <Form.Label column md={leftColNum}>
          Distribution - End Age
        </Form.Label>
        <Col className={rightColSize}>
          <Form.Control
            type="number"
            min={!!minStart ? minStart + 1 : undefined}
            max="95"
            inputMode="numeric"
            value={distributions_endAge}
            name="distributions_endAge"
            onChange={handleChange}
            isInvalid={touched.distributions_endAge && !!errors.distributions_endAge}
          />
          <Form.Control.Feedback type="invalid">
            {errors.distributions_endAge}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        controlId="amountPerYear"
        className={cn("align-items-center", {
          "is-invalid": touched.distributions_amount && !!errors.distributions_amount,
        })}
      >
        <Form.Label column md={leftColNum}>
          Distribution - Amount per year
        </Form.Label>
        <Col xs="8" sm="6" md="4">
          <CurrencyInput
            name="distributions_amount"
            value={formatCurrency(distributions_amount)}
            isInvalid={touched.distributions_amount && !!errors.distributions_amount}
            onChange={(e) => {
              setFieldValue("distributions_amount", unformatCurrency(e.target.value));
            }}
          />
          <Form.Control.Feedback type="invalid">
            {errors.distributions_amount}
          </Form.Control.Feedback>
        </Col>
        <Col xs="2">
          {maxErrors.length > 0 && (
            <OverlayTrigger trigger={["hover", "focus"]} placement={placement} overlay={popover}>
              <Button onClick={handleMax}>max</Button>
            </OverlayTrigger>
          )}
          {maxErrors.length === 0 && <Button onClick={handleMax}>max</Button>}
        </Col>
      </Form.Group>
    </div>
  );
}

export default Distribution;
