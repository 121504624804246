import { useReducer, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Layout from 'components/Layout';
import { useFactors, useUpdateFactos } from 'hooks/useFactors';
import { nFormatter } from 'utils/number';
import SpinnerText from 'components/SpinnerText';

const initialState = [{}, {}, {}, {}, {}];
const UPDATE_ACTION = 'update';
const REPLACE_ACTION = 'replace';

function reducer(state, action) {
  switch (action.type) {
    case REPLACE_ACTION:
      return action.payload;
    case UPDATE_ACTION:
      return state.map((row) => {
        if (row.id === action.payload.id) {
          return {
            ...row,
            [action.payload.column]: action.payload.value,
          };
        }
        return row;
      });
    default:
      throw new Error();
  }
}

function Factors() {
  const { data } = useFactors();
  const mutation = useUpdateFactos();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (Array.isArray(data)) {
      dispatch({
        type: REPLACE_ACTION,
        payload: data,
      });
    }
  }, [data]);

  function handleChange(e) {
    const { value, dataset } = e.target;
    if (value !== '') {
      dispatch({
        type: UPDATE_ACTION,
        payload: {
          id: parseInt(dataset.id, 10),
          column: dataset.col,
          value: parseFloat(value),
        },
      });
    }
  }

  async function handleSave(e) {
    e.preventDefault();
    try {
      await mutation.mutateAsync(state);
    } catch (e) {
      // error will be displayed in UI
    }
  }

  return (
    <Layout>
      <Row>
        <Col>
          <h2>Update Factors table</h2>
          <p>
            These values are used to help calculate the max possible distribution amount for
            Non-MECs that have distributions
          </p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Factor 1</th>
                <th scope="col">Factor 2</th>
                <th scope="col">if 2 lives,'F1</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, i) => {
                let range;
                if (row.rangeMax) {
                  range = `$${nFormatter(row.rangeMin)} - $${nFormatter(row.rangeMax)}`;
                } else {
                  range = `$${nFormatter(row.rangeMin)}+`;
                }

                return (
                  <tr key={`factors-${i}`}>
                    <th scope="row">{range}</th>
                    <td>
                      <Form.Control
                        onChange={handleChange}
                        data-col="factor1_Lives1"
                        data-id={row.id}
                        defaultValue={row.factor1_Lives1}
                      />
                    </td>
                    <td>
                      <Form.Control
                        onChange={handleChange}
                        data-col="factor2"
                        data-id={row.id}
                        defaultValue={row.factor2}
                      />
                    </td>
                    <td>
                      <Form.Control
                        onChange={handleChange}
                        data-col="factor1_Lives2"
                        data-id={row.id}
                        defaultValue={row.factor1_Lives2}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Button onClick={handleSave}>update</Button>
          {mutation.isLoading && <SpinnerText text="Saving update" />}
          {mutation.isError && (
            <div className="text-danger">An error occurred: {mutation.error.message}</div>
          )}
          {mutation.isSuccess && <div className='text-success'>Successfully updated</div>}
        </Col>
      </Row>
    </Layout>
  );
}

export default Factors;
