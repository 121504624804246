import Spinner from "react-bootstrap/Spinner";
import Icon from "components/Icon";
import { useGetS3Links } from "hooks/useScenarios";

/**
 *
 * @param {object} param0
 * @param {string} param0.scenarioId
 * @returns
 */
function ScenarioDownload({ scenarioId }) {
  const { data, isLoading } = useGetS3Links(scenarioId);
  // TODO, handle error?

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  if (data) {
    return (
      <>
        <a
          style={{ color: "#008000" }}
          title="Download Excel"
          className="d-inline-block ml-2 h3 mb-0"
          href={data.excelLink}
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="file-earmark-spreadsheet" />
          <span className="sr-only">download excel</span>
        </a>
        <a
          style={{ color: "#ff0000" }}
          title="Download WIS Illustration"
          className="d-inline-block ml-2 h3 mb-0"
          href={data.pdfLink}
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="file-earmark-pdf" />
          <span className="sr-only">Download WIS Illustration</span>
        </a>
      </>
    );
  }

	return null;
}

export default ScenarioDownload;
