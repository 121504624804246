import { useState } from 'react';
import { useFormikContext } from 'formik';
import SelectComboBox from 'react-select';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';

import { capFirst } from 'utils/string';
import { useAdvisorClients } from 'hooks/useClients';
import ManagementModal from 'components/ManagementModal/Modal';
import AddClient from 'features/ClientManagement/AddClient';
import EditClients from 'features/ClientManagement/EditClients';

function makeOption(opt) {
  const fullName = `${opt.firstName} ${opt.lastName}`;
  return {
    value: fullName,
    label: fullName,
    id: opt.id,
    firstName: opt.firstName,
    lastName: opt.lastName,
  };
}
/**
 * TODO: still need to handle: 'sample' and 'show stats only'
 */

function StepClient() {
  const { values, errors, touched, setFieldTouched, setValues } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const stepName = 'client';
  const stepId = `${stepName}Id`;
  const { data = [], error, isLoading } = useAdvisorClients(values.advisorId);
  const controlError = errors[stepName];
  const controlTouched = touched[stepName];

  let selectedValue = data.find((opt) => opt.id === values[stepId]) || '';
  if (typeof selectedValue === 'object') {
    selectedValue = makeOption(selectedValue);
  }

  /**
   * 
   * @param {Client} client
   */
  const updateValues = (client) => {
    const fullName = `${client.firstName || ''} ${client.lastName || ''}`.trim();
    setValues({
      ...values,
      [stepName]: fullName,
      [stepId]: client.id,
      owner: values.owner || fullName,
      clientFirstName: client.firstName || '',
      clientLastName: client.lastName || '',
    }, false)
  }

  function handleChange(value) {
    updateValues(value || {});
    setTimeout(() => setFieldTouched(stepName, true));
  }

  return (
    <>
      {error && (
        <Alert variant="danger">
          API error fetching {stepName}s: {error.message}
        </Alert>
      )}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <div className="d-flex justify-content-between align-items-center my-2">
              <label htmlFor="selectClient" className="form-label m-0">
                {capFirst(stepName)}
              </label>
              <Button
                disabled={!values.advisorId}
                size="sm"
                className="ml-3 mr-3 rounded-pill pill-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                manage clients
              </Button>
            </div>

            <SelectComboBox
              id="selectClient"
              isLoading={isLoading}
              isClearable
              isSearchable
              aria-invalid={controlTouched && !!controlError}
              onChange={handleChange}
              value={selectedValue}
              isOptionSelected={(option, selectValue) =>
                selectValue.some((i) => i.id === option.id)
              }
              options={data.map(makeOption)}
            />

            {controlTouched && controlError && (
              <Form.Control.Feedback className="d-block mb-3" type="invalid">
                {controlError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <ManagementModal
        id="manage-clients-modal"
        title={`Add new client to advisor: "${values.advisor}"`}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="p-3 bg-light mb-3">
          <AddClient
            advisorId={values.advisorId}
            onSaveSuccess={(newClient) => {
              updateValues(newClient);
              setShowModal(false);
            }}
          />
        </div>
        <EditClients advisorId={values.advisorId} />
      </ManagementModal>
    </>
  );
}

export default StepClient;
