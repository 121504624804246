import { useEffect } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useFormikContext } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';
import LinkButton from 'components/LinkButton';
import { leftColSize, productOptions } from 'features/IllustrationForm/constants';

/**
 * From a given Date, get 1 year ago (from current year) + 1 day
 * example: (current year is 2022)
 * - 5/15/1965 to current year 5/15/2022 - 1 year -> 5/15/2021 + 1 day = 5/16/2021
 * @param {string} bDay datetime string
 * @returns 
 */
function getOneYearFromBday(bDay) {
  return DateTime.fromISO(bDay)
    .set({ year: DateTime.now().year })
    .minus({ years: 1 })
    .plus({ days: 1 });
}

/*
  - For PPVA - current date (cannot alter)
  - For MEC - current date (cannot alter)
  - Non-MEC 
      - 6 months prior to current date, allowed to alter it
      - show indication that it has been altered (also store bool in db table)
      - if you have a birthdate, the default issue date is the day after the client’s birthday of the previous year. This is also the earliest date it could be set to
  */

function IssueDate() {
  const { handleChange, values, touched, errors, setFieldValue } = useFormikContext();
  const { birthdate, issueDate, product } = values;

  const dSix = DateTime.now().minus({ months: 6 });
  const sixMonthsAgo = dSix.toLocaleString(DateTime.DATE_SHORT);
  const fromBday = !!birthdate
    ? getOneYearFromBday(birthdate).toLocaleString(DateTime.DATE_SHORT)
    : '(birthdate not set)';

  const isSetToToday = DateTime.now().toISODate() === issueDate;
  const isSetTo6 = dSix.toISODate() === issueDate;
  const isSetToBday = !!birthdate && getOneYearFromBday(birthdate).toISODate() === issueDate;

  function handle6() {
    setFieldValue('issueDate', dSix.toISODate(), false);
  }

  function handle1year() {
    setFieldValue('issueDate', getOneYearFromBday(birthdate).toISODate(), false);
  }

  function handleToday() {
    setFieldValue('issueDate', DateTime.now().toISODate(), false);
  }

  useEffect(() => {
    if (product === productOptions.NON_MEC) {
      if (!!birthdate) {
        handle1year();
      } else {
        handle6();
      }
    } else {
      handleToday();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, birthdate]);

  return (
    <Row>
      <Form.Label column className={leftColSize} htmlFor="issueDate">
        Issue Date
      </Form.Label>
      <Col>
        <Form.Group>
          <Form.Control
            readOnly={product !== productOptions.NON_MEC}
            type="date"
            id="issueDate"
            name="issueDate"
            onChange={handleChange}
            value={issueDate}
            isInvalid={touched.issueDate && !!errors.issueDate}
            style={{ maxWidth: '200px' }}
          />
          {product === productOptions.NON_MEC && (
            <>
              <LinkButton
                onClick={handleToday}
                className={cn('issue-date', {
                  'show-check': isSetToToday,
                  'show-plus': !isSetToToday,
                })}
              >
                <small>set to today: {DateTime.now().toLocaleString(DateTime.DATE_SHORT)}</small>
              </LinkButton>
              <LinkButton
                onClick={handle6}
                className={cn('issue-date', { 'show-check': isSetTo6, 'show-plus': !isSetTo6 })}
              >
                <small>set to 6 months before today: {sixMonthsAgo}</small>
              </LinkButton>
              <LinkButton
                onClick={handle1year}
                disabled={!birthdate}
                className={cn('issue-date', {
                  'show-check': isSetToBday,
                  'show-plus': !isSetToBday,
                })}
              >
                <small>set to the day after the birth date of last year: {fromBday}</small>
              </LinkButton>
            </>
          )}
          <Form.Control.Feedback type="invalid">{errors.issueDate}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
}

export default IssueDate;
