import { useState } from "react";
import { useFormikContext } from "formik";
import SelectComboBox from "react-select";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

import { capFirst } from "utils/string";
import { useFirmAdvisors } from "hooks/useAdvisors";
import ManagementModal from "components/ManagementModal/Modal";
import AddAdvisor from "features/AdvisorManagement/AddAdvisor";
import EditAdvisors from "features/AdvisorManagement/EditAdvisors";

function makeOption(opt) {
  const fullName = `${opt.firstName} ${opt.lastName}`;
  return {
    value: fullName,
    label: fullName,
    id: opt.id,
    firstName: opt.firstName,
    lastName: opt.lastName,
  };
}

function StepAdvisor() {
  const { setFieldValue, values, errors, touched, setFieldTouched } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const stepName = "advisor";
  const stepId = `${stepName}Id`;
  const { data = [], error, isLoading } = useFirmAdvisors(values.firmId);
  const controlError = errors[stepName];
  const controlTouched = touched[stepName];

  let selectedValue = data.find((opt) => opt.id === values[stepId]) || "";
  if (typeof selectedValue === "object") {
    selectedValue = makeOption(selectedValue);
  }

  function updateValues(fullName, id) {
    setFieldValue(stepName, fullName);
    setFieldValue(stepId, id);

    // reset client if we change the advisor
    setFieldValue("client", "", false);
    setFieldValue("clientId", null, false);

    if (!values.producer && fullName) {
      setFieldValue("producer", fullName, false);
      setFieldTouched("producer", true, false);
    }
  }

  function handleChange(value) {
    updateValues(value?.value || "", value?.id || "");
    setTimeout(() => setFieldTouched(stepName, true));
  }

  return (
    <>
      {error && (
        <Alert variant="danger">
          API error fetching {stepName}s: {error.message}
        </Alert>
      )}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <div className="d-flex justify-content-between align-items-center my-2">
              <label htmlFor="advisorSelect" className="form-label m-0">
                {capFirst(stepName)}
              </label>
              <Button
                disabled={!values.firmId}
                size="sm"
                className="ml-3 mr-3 rounded-pill pill-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                manage advisors
              </Button>
            </div>

            <SelectComboBox
              id="advisorSelect"
              isLoading={isLoading}
              isClearable
              isSearchable
              aria-invalid={controlTouched && !!controlError}
              onChange={handleChange}
              value={selectedValue}
              isOptionSelected={(option, selectValue) =>
                selectValue.some((i) => i.id === option.id)
              }
              options={data.map(makeOption)}
            />

            {controlTouched && controlError && (
              <Form.Control.Feedback className="d-block mb-3" type="invalid">
                {controlError}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <ManagementModal
        id="manage-advisors-modal"
        title={`Manage Advisors for ${values.firm}`}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      >
        <div className="p-3 bg-light mb-3">
          <AddAdvisor
            firmId={values.firmId}
            onSaveSuccess={(newAdvisor) => {
              const fullName = `${newAdvisor.firstName} ${newAdvisor.lastName}`;
              updateValues(fullName, newAdvisor.id);
              setShowModal(false);
            }}
          />
        </div>
        <EditAdvisors firmId={values.firmId} />
      </ManagementModal>
    </>
  );
}

export default StepAdvisor;
