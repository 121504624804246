import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from 'components/Layout';

import IllustrationForm from 'features/IllustrationForm/Form';

function NewForm() {
  return (
    <Layout>
      <Row>
        <Col>
          <IllustrationForm />
        </Col>
      </Row>
    </Layout>
  );
}

export default NewForm;