import Spinner from 'react-bootstrap/Spinner';

function SpinnerText({ text, size = "sm" }) {
  return (
    <>
      <Spinner animation="border" size={size} />
      <span className="d-inline-block ml-2">{text}</span>
    </>
  );
}

export default SpinnerText;