import { useEffect, useState } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Icon from 'components/Icon';
import cn from 'classnames';
import { sumArr } from 'utils/array';
import { formatCurrency, unformatCurrency } from 'utils/currency';
import CurrencyInput from 'components/CurrencyInput';
import { fixedSplitWhole } from 'features/IllustrationForm/helpers';
import { leftColNum, leftColSize, rightColSize, rightColNum } from './constants';

function Amount() {
  const { handleChange, values, errors, touched, setFieldValue } = useFormikContext();
  const [sum, setSum] = useState(sumArr(values.annualPays));
  const { totalAmount, numOfPayments, annualPays = [] } = values;

  const isValid = sum === totalAmount;

  useEffect(() => {
    setSum(sumArr(values.annualPays));
  }, [values.totalAmount, values.numOfPayments, values.annualPays]);

  function updateTotal() {
    setFieldValue('totalAmount', sum);
  }

  return (
    <>
      <Form.Group as={Row} controlId="totalAmount" className="align-items-center">
        <Form.Label column className={leftColSize}>
          Amount
        </Form.Label>
        <Col className={rightColSize}>
          <CurrencyInput
            name="totalAmount"
            value={formatCurrency(totalAmount)}
            onChange={(e) => {
              setFieldValue('totalAmount', unformatCurrency(e.target.value));
            }}
            isInvalid={touched.totalAmount && !!errors.totalAmount}
          />
          <Form.Control.Feedback type="invalid">{errors.totalAmount}</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="numOfPayments" className="align-items-center">
        <Form.Label column className={leftColSize}>
          pays
        </Form.Label>
        <Col className={rightColSize}>
          <Form.Control
            style={{ maxWidth: '100px' }}
            name="numOfPayments"
            value={numOfPayments}
            onChange={(e) => {
              handleChange(e);
              const pays = parseInt(e.target.value.replace(/[^0-9]/g, ''), 10);
              if (pays > 0) {
                setFieldValue('annualPays', fixedSplitWhole(totalAmount, pays));
              } else {
                setFieldValue('annualPays', []);
              }
            }}
            type="number"
            inputMode="numeric"
            min="0"
          />
        </Col>
      </Form.Group>

      <FieldArray
        name="annualPays"
        render={({ push }) => (
          <>
            {annualPays.length > 0 && (
              <>
                <Row>
                  <Col md={leftColNum} />
                  <Col md={rightColNum}>
                    <p className={cn({ 'text-danger': !isValid })}>
                      sum total of payments below: ${formatCurrency(sum)}
                      {!isValid && (
                        <Button onClick={updateTotal} className="ml-2" size="sm">
                          update total
                        </Button>
                      )}
                    </p>
                    {Array(numOfPayments)
                      .fill(0)
                      .map((_, i) => {
                        const value = annualPays[i];
                        return (
                          <div key={`amount${i}`} className="mb-3">
                            <Form.Group as={Row} controlId={`annualPays${i}`}>
                              <Form.Label column xs="3" sm="2">
                                pay {i + 1}
                              </Form.Label>
                              <Col xs="9" sm="8">
                                <CurrencyInput
                                  name={`annualPays[${i}]`}
                                  value={formatCurrency(value)}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `annualPays[${i}]`,
                                      unformatCurrency(e.target.value)
                                    );
                                  }}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
                <Row>
                  <Col md={leftColNum} />
                  <Col md={rightColNum}>
                    <Button
                      onClick={() => {
                        push(0);
                        setFieldValue('numOfPayments', values.numOfPayments += 1);
                      }}
                    >
                      <Icon name="plus-lg" className="d-inline-block mr-2" />
                      Add another pay
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      />
    </>
  );
}

export default Amount;
