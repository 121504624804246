import { useState } from 'react';
import _debounce from 'lodash-es/debounce';
import { usePaginatedAdvisors, useAdvisorSearch } from 'hooks/useAdvisors';
import { ListGroup, Form } from 'react-bootstrap';
import SpinnerText from 'components/SpinnerText';
import Paginator from 'components/Paginator';
import AdvisorRow from 'features/AdvisorManagement/AdvisorRow';

function EditAdvisors({ firmId }) {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data: searchData } = useAdvisorSearch(firmId, search);
  const {
    data: paginatedData = {
      results: [],
    },
    isLoading,
  } = usePaginatedAdvisors(firmId, page);

  let listData = searchData || paginatedData?.results || [];

  function onChange(e) {
    setSearch(e.target.value);
  }
  const debouncedOnChange = _debounce(onChange, 300);

  const title = searchData ? `Search results:` : 'Advisors';

  if (isLoading) {
    return <SpinnerText text="loading advisors" />;
  }

  return (
    <div>
      <Form.Group controlId="filterAdvisors">
        <Form.Label className="sr-only">Search</Form.Label>
        <Form.Control placeholder="search" onChange={debouncedOnChange} />
      </Form.Group>
      <p className="mt-3"><b>{title}</b></p>
      {listData.length === 0 && (
          <p>
            <i>none</i>
          </p>
        )}
      <ListGroup as="ul" className="mt-4">
        {listData.map((advisor) => (
          <AdvisorRow key={advisor.id} advisor={advisor} />
        ))}
      </ListGroup>
      {!searchData && paginatedData?.totalPages > 0 && (
        <Paginator
          active={page}
          total={paginatedData.totalPages}
          onClick={(pagenum) => {
            setPage(pagenum);
          }}
        />
      )}
    </div>
  );
}

export default EditAdvisors;
