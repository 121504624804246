import * as yup from "yup";
import { DateTime } from "luxon";
import { productOptions } from "features/IllustrationForm/constants";
import { getStartAge } from "features/IllustrationForm/helpers";

yup.addMethod(yup.array, "annualPays", function (message) {
  return this.test("totalAmount-annualPays", message, function (list = []) {
    const sum = list.reduce((acc, val) => val + acc, 0);
    return sum === this.parent.totalAmount;
  });
});

/**
 * For a given date, ignoring year, return a date that is exactly 1 year ago + 1 day from that date 
 * example:
 * - current year: 2022
 * - input: Nov 1, 1978
 * - returns: Nov 2, 2021
 *
 * @param {string} d a date string
 * @returns a date string
 */
function getMinDate(d) {
  const fixed = new Date(d).toISOString();
  return DateTime.fromISO(fixed)
    .set({ year: DateTime.now().year })
    .minus({ years: 1 })
    .plus({ days: 1 })
    .toISODate();
}

const gender = yup.string().required("Must select a gender");

const schemaObj = {
  firm: yup.string().required(),
  firmId: yup.string().required(),
  advisor: yup.string().required(),
  advisorId: yup.string().required(),
  client: yup.string().required(),
  clientId: yup.string().required(),

  type: yup.string().required(),

  // not sure if this one is required or not
  need: yup.string().required(),

  product: yup
    .string()
    .required()
    .when("isExchange", {
      is: true,
      then: yup
        .string()
        .matches(new RegExp(productOptions.MEC, "i"), "Product must be a MEC if this is a 1035X"),
    }),

  issueDate: yup
    .date()
    .when(["birthdate", "product"], (birthdate, product, field) => {
      if (product === productOptions.NON_MEC && !!birthdate) {
        // one day after bDay of previous year
        const minDate = getMinDate(birthdate);
        return field.min(
          minDate,
          `Date can not be older than ${DateTime.fromISO(minDate).toLocaleString(
            DateTime.DATE_SHORT
          )} (A day after the birth date of last year)`
        );
      }

      if (product === productOptions.NON_MEC && !birthdate) {
        // 6 months prior to current date
        const halfYearAgo = DateTime.now().minus({ months: 6 });
        return field.min(
          halfYearAgo.toISODate(),
          `Date can not be older than 6 months before today (${halfYearAgo.toLocaleString(
            DateTime.DATE_SHORT
          )}) unless a birthdate was provided`
        );
      }

      return field;
    })
    .required(),

  hasDistributions: yup.bool(),

  distributions_amount: yup
    .mixed()
    .nullable()
    .when("hasDistributions", {
      is: true,
      then: yup
        .number()
        .positive("Amount must be greater than 0")
        .integer("")
        .required("You must enter a distribution yearly amount"),
    }),
  distributions_startAge: yup
    .number()
    .nullable()
    .max(95, "Max distribution start age is 95")
    .when(
      ["hasDistributions", "insured", "age", "numOfPayments"],
      (hasDistributions, insured, age, numOfPayments, field) => {
        if (!hasDistributions) return field;
        if ((insured || age) && numOfPayments > 0) {
          const min = getStartAge(insured, age, numOfPayments);
          return field
            .min(min, `Minimum start age is ${min}`)
            .required("Distribution start age is required");
        }
        return field.required("Distribution start age is required");
      }
    ),
  distributions_endAge: yup
    .number()
    .nullable()
    .max(95, "Max distribution end age is 95")
    .when(
      ["hasDistributions", "insured", "age", "numOfPayments"],
      (hasDistributions, insured, age, numOfPayments, field) => {
        if (!hasDistributions) return field;
        if ((insured || age) && numOfPayments > 0) {
          const min = getStartAge(insured, age, numOfPayments) + 1;
          return field
            .min(min, `Minimum end age is ${min}`)
            .required("Distribution end age is required");
        }
        return field.required("Distribution end age is required");
      }
    ),

  isExchange: yup.bool(),

  policyAge: yup
    .mixed()
    .nullable()
    .when("isExchange", {
      is: true,
      then: yup
        .number()
        .positive()
        .integer()
        .required("You must enter a policy age")
        .min(7, "Policy age must be 7 years or older"),
    }),

  owner: yup.string().required(),
  age: yup.number().required("Age or Birthdate is required"),
  birthdate: yup.date("invalid date format"),
  gender,

  /**
   * `insured` is only used when product is NOT a PPVA
   */
  insured: yup.array().when("product", {
    is: (product) => {
      return product !== productOptions.PPVA;
    },
    then: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup.string().required(),
          lastName: yup.string(),
          age: yup
            .number()
            .positive("Age must be greater than 0")
            .integer("Age must be a whole number")
            .required("required"),
          gender,
          health: yup.string().required("required"),
        })
      )
      .min(1, "You must include at least 1 insured")
      .max(3, "You can only include a max of 3 insured"),
  }),

  totalAmount: yup.number().positive().required("You must enter a total amount"),
  numOfPayments: yup.number().positive().integer().required("You must enter number number of pays"),

  annualPays: yup
    .array()
    .of(yup.number().positive())
    .min(1, "there should be at least 1 of these")
    .annualPays("Sum total of annual pays does not match the total amount"),

  producer: yup.string().required(),
  pricingTable: yup.string().required(),
  taxRate: yup
    .number()
    .integer() // no fractions
    .min(20, "Tax rate must be a minimum of 20% or a max of 70%")
    .max(70, "Tax rate must be a minimum of 20% or a max of 70%")
    .required("You must provide a tax rate"),
  rateOfReturn: yup.number().min(4).max(10).required(),
};

const schema = yup.object().shape(schemaObj);

export default schema;
