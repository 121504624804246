import { useUpdateFirm } from 'hooks/useFirms';
import { Card } from 'react-bootstrap';
import SpinnerText from 'components/SpinnerText';
import FirmForm from 'features/FirmManagement/FirmForm';

function FirmUpdate({ firm, onCancel, onSuccess }) {
  const mutation = useUpdateFirm();

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    await mutation.mutateAsync({ ...firm, name: formData.get('name') });
    onSuccess();
  }

  return (
    <Card>
      <Card.Header>Update firm</Card.Header>
      <Card.Body>
        <FirmForm firm={firm} onSubmit={onSubmit} onCancel={onCancel} actionLabel="Update" />
        {mutation.isLoading && <SpinnerText text="Saving update to firm" />}
        {mutation.isError && (
          <div className="text-danger">An error occurred: {mutation.error.message}</div>
        )}
      </Card.Body>
    </Card>
  );
}

export default FirmUpdate;
