import Spinner from 'react-bootstrap/Spinner';

function BigPageSpinner({ text }) {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <Spinner animation="border" />
      <p>{text}</p>
    </div>
  );
}

export default BigPageSpinner;
