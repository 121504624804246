import { useQuery, useMutation, useQueryClient } from "react-query";
import _merge from "lodash-es/merge";
import { get, post, del } from "utils/fetch";
import { SCENARIOS } from "hooks/constants";

const cacheKey = SCENARIOS.CACHE_KEY;
const endpoint = SCENARIOS.ENDPOINT;

export function useClientScenarios(clientId) {
  return useQuery([cacheKey, clientId], () => get(`${endpoint}?clientId=${clientId}`), {
    enabled: !!clientId,
  });
}

export function useAddNewScenario() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ clientId, data }) => {
      const merged = _merge({ clientId }, data);
      return post(endpoint, merged);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );
}

/**
 * Return a paginated list of Scenarios for a specific client
 * @param {string} clientId
 * @param {number} page
 * @returns {import('react-query').UseQueryResult<PaginatedScenario>}
 */
export function usePaginatedScenarios(clientId, page = 1) {
  return useQuery(
    [cacheKey, clientId, { page }],
    () => get(`${endpoint}/paginated?clientId=${clientId}&page=${page}`),
    {
      keepPreviousData: true,
      enabled: !!clientId,
    }
  );
}

export function useDeleteScenario() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ scenarioId, clientId }) => {
      return del(`${endpoint}/${scenarioId}?clientId=${clientId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cacheKey);
      },
    }
  );
}

/**
 * Gets signed urls that allow temporary access to specific scenario's objects in an S3 bucket
 * @param {string} scenarioId uuid of the scenario
 * @returns { import('react-query').UseQueryResult<{pdfLink: string, excelLink: string}> }
 */
export function useGetS3Links(scenarioId) {
  return useQuery([cacheKey, "links", scenarioId], () => get(`${endpoint}/${scenarioId}/links`), {
    enabled: !!scenarioId,
    // the s3 urls are good for 15 minutes, so that's how long the cache time should be for
    cacheTime: 15 * 60 * 1000,
    // consider them stale after 13 min.
    staleTime: 13 * 60 * 1000,
  });
}
