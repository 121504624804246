/**
 * Handle different error formats returned from the API 
 * @param {object|string} message 
 */
export function getErrorMesssage(message) {
  let errorMessage = null;
  
  try {
    const errData = JSON.parse(message);
    const code = errData.status || errData.StatusCode;
    switch(code) {
      case 400:
        if (typeof errData.errors === 'object') {
          // {,"errors":{"Email":["The Email field is required."],"PhoneNumber":["The PhoneNumber field is required."]}}
          errorMessage = Object.values(errData.errors).map(arr => arr.join(', ')).join(', ');
        } else {
          errorMessage = errData.title;
        }
        break;
      case 500:
        errorMessage = "An internal error occured";
        break;
      default:
        errorMessage = errData.title;
    }

  } catch (e) {
    errorMessage = message;
  }
  return errorMessage;
}
