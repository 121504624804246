import { matchSorter } from 'match-sorter';
import { sumArr } from 'utils/array';

export function selectByName(name, list = []) {
  return list.find((item) => item.name === name);
}

/**
 * filters an array of objects containing the properties 'firstName' and 'lastName' using
 * matchSorter
 * @param {string} term
 * @param {object[]} list
 * @returns
 */
export function filterByFullName(term = '', list) {
  if (term.trim() === '') return list;
  return matchSorter(list, term, {
    keys: [
      {
        key: 'firstName',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
      {
        key: 'lastName',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
    ],
  });
}

/**
 * filters an array of objects containing the properties 'firstName' and 'lastName' using
 * matchSorter
 * @param {string} term
 * @param {object[]} list
 * @returns
 */
export function filterByName(term = '', list) {
  if (term.trim() === '') return list;
  return matchSorter(list, term, {
    keys: [
      {
        key: 'name',
        maxRanking: matchSorter.rankings.CONTAINS,
      },
    ],
  });
}

/**
 * 
 * @param {Scenario} scenario
 * @param {string} ext 
 * @returns 
 */
export function makeS3Link(scenario, ext) {
  const { clientId, id, fileName } = scenario;
  const bucketName = process.env.AWS_S3_BUCKETNAME || 'wis-illustrations-dev';

  if (fileName) {
    return `https://${bucketName}.s3.amazonaws.com/${clientId}/${id}/${fileName}.${ext}`;
  }
  
  return `https://${bucketName}.s3.amazonaws.com/${clientId}/${id}.${ext}`;
}

export function ingeniusExcelLink(excelDownloadId) {
  const domain = process.env.INGENIUS_DOMAIN || 'dev.ingenius.ws';
  return `https://${domain}/api/download/Q${excelDownloadId}?excel=1`;
}

/**
 * Round down to the nearest 2nd decimal place
 * @param {number} n
 * @returns {number}
 */
function floorTo100th(n) {
  return Math.floor(n * 100) / 100;
}

function roundTo100th(n) {
  return Math.round(n * 100) / 100;
}

/**
 * Divides `total` by `pays` and returns an array with length of `pays` filled with the quotient.
 * If the split if not even will add the remainder to the last item of the array.
 *
 * The returned array from this functions return numbers with fractions up the 100th place.
 * @param {number} total
 * @param {number} pays
 * @returns {number[]}
 */
export function fixedSplit(total, pays) {
  const split = total / pays;
  if (total % pays === 0) {
    return Array(pays).fill(split);
  }

  const result = Array(pays)
    .fill(split)
    .map((amt) => floorTo100th(amt));

  const sum = result.reduce((a, b) => a + b, 0);
  const diff = roundTo100th(total - sum);
  const last = result[result.length - 1];
  result[result.length - 1] = last + diff;
  return result;
}

/**
 * Divides `total` by `pays` and returns an array with length of `pays` filled with the quotient.
 * If the split if not even will add the remainder to the last item of the array
 *
 * This function ensures all the numbers in the array are whole numbers
 * @param {number} total
 * @param {number} pays
 * @returns {number[]}
 */
export function fixedSplitWhole(total, pays) {
  const split = total / pays;
  if (total % pays === 0) {
    return Array(pays).fill(split);
  }

  const result = Array(pays)
    .fill(split)
    .map((amt) => Math.floor(amt));

  const sum = sumArr(result);
  const diff = total - sum;
  const last = result[result.length - 1];
  result[result.length - 1] = last + diff;
  return result;
}

/**
 * Get youngest age from insured/age + number of pays + 1
 * @param {object[]} insured
 * @param {number} age
 * @param {number} pays
 */
export function getStartAge(insured, age, pays) {
  let num = age;
  if (Array.isArray(insured) && insured.length && !!insured[0].age) {
    num = insured.reduce(
      (youngest, current) => (current.age < youngest ? current.age : youngest),
      999
    );
  }
  return num + pays + 1;
}

/**
 * Renames a file while downloading
 * adapted from src: https://stackoverflow.com/a/56195387
 * @param {string} url 
 * @param {string} name 
 */
export const downloadAs = async (url, name) => {
  /**
   * @type {RequestInit}
   */
  const options = {
    headers: { "Content-Type": "application/octet-stream" },
    method: 'GET',
  };

  try {
    const response = await fetch(url, options);
    const blob = await response.blob();

    const a = document.createElement("a");
      a.href =  window.URL.createObjectURL(blob);;
      a.download = name;
      a.click();
  } catch (err) {
    console.log("error", err);
  }
};