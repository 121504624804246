import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FormContent from 'components/OldForm';
import Layout from 'components/Layout';

function LegacyForm() {
  return (
    <Layout>
      <Row>
        <Col lg="10">
          <FormContent />
        </Col>
      </Row>

      <span style={{ fontSize: '12px' }}>
        <br />
        <p>How to name files by product type (not case sensitive):</p>
        <ol>
          <li>
            If the file name contains "nonmec" or "non-mec", it will run as a Non-MEC
            <ul>
              <li>
                If the file name contains "distributions", then it will assume the excel document is
                the manually formatted variant which models distributions over time.
              </li>
            </ul>
          </li>
          <li>If the file name contains "mec", it will run as a MEC</li>
          <li>
            If the file name contains "ppva", it will run as an Annutiy
            <ul>
              <li>
                If the file name ALSO contains "charity", it will run as a charity illustration
              </li>
              <li>Otherwise, it will run as an Alternatives illustration</li>
            </ul>
          </li>
        </ol>
        <br />
        <p>Sample Files</p>
        <ul>
          <li>
            <a href="/Content/SampleFiles/MNS50 PRF $39.2m Non-MEC B to A 6% Base N-Gtd (Distributions) (2).xlsx">
              MNS50 PRF $39.2m Non-MEC B to A 6% Base N-Gtd (Distributions) (2).xlsx
            </a>
          </li>
          <li>
            <a href="/Content/SampleFiles/MNS50_MEC(2019-10-03T15-51-26).xlsx">
              MNS50_MEC(2019-10-03T15-51-26).xlsx
            </a>
          </li>
          <li>
            <a href="/Content/SampleFiles/MNS50_NonMEC(2019-10-03T16-04-13).xlsx">
              MNS50_NonMEC(2019-10-03T16-04-13).xlsx
            </a>
          </li>
          <li>
            <a href="/Content/SampleFiles/MNS50_PPVA(2019-10-21T04-26-15).xlsx">
              MNS50_PPVA(2019-10-21T04-26-15).xlsx
            </a>
          </li>
          <li>
            <a href="/Content/SampleFiles/Sam_PPVA(direct Annuity charity PreTax).xlsx">
              Sam_PPVA(direct Annuity charity PreTax).xlsx
            </a>
          </li>
        </ul>
      </span>
    </Layout>
  );
}

export default LegacyForm;