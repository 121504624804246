import { useFormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { productOptions, leftColSize, needOptions } from "features/IllustrationForm/constants";

function Product() {
  const { handleChange, values, touched, errors, setFieldValue } = useFormikContext();
  const { hasDistributions, product, optionBtoA } = values;

  const onSelect = (e) => {
    handleChange(e);
    if (e.target.value === productOptions.PPVA) {
      setFieldValue("need", needOptions.CHARITY);
    }
  };

  return (
    <Row className="align-items-center">
      <Form.Label column className={leftColSize} htmlFor="product">
        Product
      </Form.Label>
      <Col sm="4">
        <Form.Group>
          <Form.Control
            id="product"
            name="product"
            as="select"
            onChange={onSelect}
            value={product}
            isInvalid={touched.product && !!errors.product}
          >
            <option disabled value="">
              select one
            </option>
            {Object.values(productOptions).map((opt) => {
              return (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              );
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{errors.product}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      {product === productOptions.NON_MEC && (
        <>
          <Col sm="4">
            <Form.Check
              className="mt-3"
              type="checkbox"
              id="hasDistributions"
              label="Distribution?"
              name="hasDistributions"
              checked={hasDistributions}
              onChange={handleChange}
            />
  
            <Form.Check
              className="mt-3"
              type="checkbox"
              id="optionBtoA"
              label="Option B to A"
              name="optionBtoA"
              checked={optionBtoA}
              onChange={handleChange}
            />
          </Col>
        </>
      )}
    </Row>
  );
}

export default Product;
