import { useUpdateAdvisor } from 'hooks/useAdvisors';
import { Card } from 'react-bootstrap';
import SpinnerText from 'components/SpinnerText';
import AdvisorForm from 'features/AdvisorManagement/AdvisorForm';

function AdvisorUpdate({ advisor, onCancel, onSuccess }) {
  const mutation = useUpdateAdvisor();

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    await mutation.mutateAsync({
      ...advisor,
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      email: formData.get('email'),
      phoneNumber: formData.get('phoneNumber'),
    });
    onSuccess();
  }

  return (
    <Card>
      <Card.Header>Update Advisor</Card.Header>
      <Card.Body>
        <AdvisorForm advisor={advisor} onSubmit={onSubmit} onCancel={onCancel} />
        {mutation.isLoading && <SpinnerText text="Saving update" />}
        {mutation.isError && (
          <div className="text-danger">An error occurred: {mutation.error.message}</div>
        )}
      </Card.Body>
    </Card>
  );
}

export default AdvisorUpdate;
