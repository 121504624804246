import { useState } from "react";
import { ListGroup, Button, Alert } from "react-bootstrap";
import Icon from "components/Icon";
import ConfirmDelete from "components/ConfirmDelete";
import ClientUpdate from "features/ClientManagement/ClientUpdate";
import { useDeleteClient } from "hooks/useClients";
import SpinnerText from "components/SpinnerText";
import cn from "classnames";
import _omit from "lodash-es/omit";

const omitList = ["advisor", "advisorId", "created", "createdBy", "id", "isDeleted", "modified"];

function ClientRow({ client }) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const mutation = useDeleteClient();

  const fullName = `${client.firstName} ${client.lastName || ""}`;

  const missing = Object.keys(_omit(client, omitList))
    .filter((key) => !client[key])
    .join(", ");

  async function handleDelete() {
    try {
      await mutation.mutateAsync(client.id);
      setShowDelete(false);
    } catch (e) {
      // error is going to be part of mutation.error
    }
  }

  return (
    <>
      <ListGroup.Item
        as="li"
        data-client={client.id}
        className={cn("d-flex justify-content-between align-items-center", {
          fadeOut: mutation.isSuccess,
        })}
      >
        <div className={cn("mr-auto d-flex align-items-center", { deleted: mutation.isSuccess })}>
          <div className="font-weight-bold">{fullName}</div>
          {!!missing && (
            <small className="text-muted ml-3">
              missing <i>{missing}</i>
            </small>
          )}
        </div>
        <div hidden={mutation.isSuccess}>
          <Button variant="info" title="Edit Client" onClick={() => setShowEdit(!showEdit)}>
            <Icon name="pencil" />
            <div className="sr-only">Edit Client</div>
          </Button>

          <Button
            variant="danger"
            title="Delete Client"
            className="ml-3"
            onClick={() => setShowDelete(!showDelete)}
          >
            <Icon name="trash" />
            <div className="sr-only">Delete Client</div>
          </Button>
        </div>
      </ListGroup.Item>
      {showDelete && (
        <ListGroup.Item className="bg-secondary">
          <ConfirmDelete
            title={`Delete Client: ${fullName}`}
            entityId={client.id}
            onCancel={() => {
              setShowDelete(false);
            }}
            onDelete={handleDelete}
          >
            <p>
              <strong>Please confirm that you want to delete this client?</strong>{" "}
            </p>
            <Alert variant="danger">
              <Icon name="exclamation-triangle" className="h5 m-0" /> This will delete{" "}
              <b>
                <em>all</em>
              </b>{" "}
              Scenarios for this client. This can not be undone.
            </Alert>
            {mutation.isLoading && <SpinnerText text="Deleting Client" />}
            {mutation.isError && (
              <div className="text-danger">An error occurred: {mutation.error.message}</div>
            )}
          </ConfirmDelete>
        </ListGroup.Item>
      )}
      {showEdit && (
        <ListGroup.Item className="bg-secondary">
          <ClientUpdate
            client={client}
            onCancel={() => setShowEdit(false)}
            onSuccess={() => setShowEdit(false)}
          />
        </ListGroup.Item>
      )}
    </>
  );
}

export default ClientRow;
