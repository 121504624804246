import Modal from 'react-bootstrap/Modal';

function ManageModal({ show = false, handleClose, title = '', children, id }) {
  return (
    <Modal id={id} show={show} onHide={handleClose} size="xl">
      <Modal.Header>
        {title && <Modal.Title>{title}</Modal.Title>}
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default ManageModal;
