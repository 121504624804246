import { useQuery, useMutation, useQueryClient } from 'react-query';
import { post, get, put, del } from 'utils/fetch';
import { FIRMS, ADVISORS, CLIENTS, SCENARIOS } from 'hooks/constants';

const cacheKey = FIRMS.CACHE_KEY;
const endpoint = FIRMS.ENDPOINT;

/**
 * Get all of the firms saved in our system
 * @returns {Promise<Firm[]>}
 */
export function useFirms() {
  return useQuery(cacheKey, () => get(endpoint));
}

/**
 *
 * @param {number} page
 * @returns {Promise<PaginatedFirm>}
 */
export function usePaginatedFirms(page = 1) {
  return useQuery([cacheKey, { page }], () => get(`${endpoint}/paginated?page=${page}&sort=desc`), {
    keepPreviousData: true,
  });
}

/**
 * Filter firms using search term
 * @returns {Promise<Firm[]>}
 */
export function useFirmSearch(searchString) {
  return useQuery([cacheKey, { searchString }], () => get(`${endpoint}?search=${searchString}`), {
    enabled: !!searchString,
  });
}

/**
 * Get a single Firm
 * @param {string} id uuid
 * @returns {Promise<Firm>}
 */
export function useGetFirm(id) {
  return useQuery([cacheKey, id], () => get(`${endpoint}/${id}`), {
    enabled: !!id,
  });
}

export function useAddFirm() {
  const queryClient = useQueryClient();

  return useMutation((data) => post(endpoint, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKey);
    },
  });
}

export function useUpdateFirm() {
  const queryClient = useQueryClient();

  return useMutation((data) => put(endpoint, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKey);
    },
  });
}

export function useDeleteFirm() {
  const queryClient = useQueryClient();

  return useMutation((id) => del(`${endpoint}/${id}`), {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(cacheKey);
        // when we delete a firm it should cascade and delete all related advisors, clients, and
        // scenarios, so we'll need to invalidate those caches as well. The timeout is to let an
        // animation finish
        queryClient.invalidateQueries(ADVISORS.CACHE_KEY);
        queryClient.invalidateQueries(CLIENTS.CACHE_KEY);
        queryClient.invalidateQueries(SCENARIOS.CACHE_KEY);
      }, 1000);
    },
  });
}
