import { useFormikContext } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { productOptions, leftColNum, rightColNum } from 'features/IllustrationForm/constants';

function Exchange() {
  const { values, touched, errors, handleChange, setFieldValue } = useFormikContext();

  function handleExchange(e) {
    handleChange(e)
    if (e.target.checked && values.product === productOptions.NON_MEC) {
      setFieldValue('product', productOptions.MEC);
    }
  }

  return (
    <>
      <Form.Group as={Row} controlId="isExchange">
        <Form.Label column xs={12} sm={leftColNum}>
          Is this a Section 1035 Exchange?
        </Form.Label>
        <Col xs={12} sm={rightColNum} style={{ paddingLeft: '2rem' }}>
          <Form.Check
            type="checkbox"
            id="isExchange"
            name="isExchange"
            checked={values.isExchange}
            onChange={handleExchange}
          />
          <Form.Text as="p" className="ml-2">
            For Non-MEC, selecting this will convert it to a MEC for illustration purposes
          </Form.Text>
        </Col>
      </Form.Group>
      {values.isExchange && (
        <Row>
          <Col md={leftColNum} />
          <Col md={rightColNum}>
            <Form.Group as={Row} controlId="policyAge" className="align-items-center">
              <Form.Label column xs={leftColNum} sm="3">
                Age of policy
              </Form.Label>
              <Col xs={rightColNum} sm="3">
                <Form.Control
                  type="number"
                  inputMode="numeric"
                  min="7"
                  value={values.policyAge}
                  onChange={handleChange}
                  isInvalid={touched.policyAge && !!errors.policyAge}
                />
                <Form.Control.Feedback type="invalid">{errors.policyAge}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Exchange;
