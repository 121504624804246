import { useUpdateClient } from 'hooks/useClients';
import { Card } from 'react-bootstrap';
import SpinnerText from 'components/SpinnerText';
import ClientForm from 'features/ClientManagement/ClientForm';

function ClientUpdate({ client, onCancel, onSuccess }) {
  const mutation = useUpdateClient();

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    await mutation.mutateAsync({
      ...client,
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      email: formData.get('email'),
      phoneNumber: formData.get('phoneNumber'),
    });
    onSuccess();
  }

  return (
    <Card>
      <Card.Header>Update Client</Card.Header>
      <Card.Body>
        <ClientForm client={client} onSubmit={onSubmit} onCancel={onCancel} />
        {mutation.isLoading && <SpinnerText text="Saving update" />}
        {mutation.isError && (
          <div className="text-danger">An error occurred: {mutation.error.message}</div>
        )}
      </Card.Body>
    </Card>
  );
}

export default ClientUpdate;
