import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { formatCurrency } from 'utils/currency';

function CurrencyInput({ onChange, ...props }) {
  function handleChange(event) {
    const { value } = event.target;
    const onlyNumbers = value.replace(/[^0-9]/g, '');
    event.target.value = formatCurrency(onlyNumbers, 2);
    onChange(event);
  }

  return (
    <InputGroup>
      <InputGroup.Text>$</InputGroup.Text>
      <Form.Control {...props} inputMode="numeric" onChange={handleChange} />
    </InputGroup>
  );
}

export default CurrencyInput;
