import { useFormikContext } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import {
  templateOptions,
  brandingOptions,
  leftColNum,
  rightColNum,
  rightColMdNum,
} from 'features/IllustrationForm/constants';

function OutputOptions() {
  const { handleChange, values } = useFormikContext();
  const { includeCoverPage, includeGraphics, includeLedger, staticTemplate, branding } =
    values;

  return (
    <>
      <Row>
        <Col>
          <h2 className="form-style-heading">Output Options</h2>
        </Col>
      </Row>

      <Form.Group as={Row} controlId="includeCoverPage" className="mb-3 align-items-center">
        <Form.Label column xs={leftColNum + 1} sm={leftColNum}>
          Cover Page
        </Form.Label>
        <Col xs={rightColNum - 1} sm={rightColNum}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeCoverPage"
            checked={includeCoverPage}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="includeGraphics" className="mb-3 align-items-center">
        <Form.Label column xs={leftColNum + 1} sm={leftColNum}>
          Graphics
        </Form.Label>
        <Col xs={rightColNum - 1} sm={rightColNum}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeGraphics"
            checked={includeGraphics}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="includeLedger" className="mb-3 align-items-center">
        <Form.Label column xs={leftColNum + 1} sm={leftColNum}>
          Ledger
        </Form.Label>
        <Col xs={rightColNum - 1} sm={rightColNum}>
          <Form.Check
            inline
            className="form-check"
            onChange={handleChange}
            name="includeLedger"
            checked={includeLedger}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="staticTemplate" className="mb-3">
        <Form.Label column sm={leftColNum}>
          Intro/Summary Template
        </Form.Label>
        <Col sm={rightColNum} md={rightColMdNum}>
          <FormControl
            as="select"
            name="staticTemplate"
            onChange={handleChange}
            value={staticTemplate}
          >
            {Object.values(templateOptions).map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </FormControl>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="branding" className="mb-3">
        <Form.Label column sm={leftColNum}>
          Branding
        </Form.Label>
        <Col sm={rightColNum} md={rightColMdNum}>
          <FormControl as="select" name="branding" onChange={handleChange} value={branding}>
            {Object.values(brandingOptions).map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </FormControl>
        </Col>
      </Form.Group>
    </>
  );
}

export default OutputOptions;
