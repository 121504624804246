import styles from './LinkButton.module.scss';
import cn from 'classnames';

function LinkButton({ children, className = '', ...props }) {
  return (
    <button type="button" className={cn('btn-unstyled', styles.linkBtn, className)} {...props}>
      {children}
    </button>
  );
}

export default LinkButton;
