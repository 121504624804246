import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

function ConfirmDelete({ entityId, title, onCancel, onDelete, children }) {
  const [text, setText] = useState('');

  return (
    <Card as="form" id={`delete-${entityId}`} >
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        {children}
        <Form.Group controlId={`confirm-del-${entityId}`} className="mb-3">
          <Form.Label className="font-weight-normal">
            Type <em>I CONFIRM</em> in all caps
          </Form.Label>
          <Form.Control
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={text !== 'I CONFIRM'} variant="success" onClick={onDelete}>
            Confirm Delete
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ConfirmDelete;
